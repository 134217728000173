import { skipHydrate } from 'pinia'
import {
  ADYEN_CHECKOUT_SESSION,
  CHECKOUT_INFO,
  CHECKOUT_STEPS,
  COMPLETED_VIEWS,
} from 'configuration/global.configuration'
import { DEFAULT_CHECKOUT_INFORMATION } from 'lib/checkout'
import { getByCountryCode, patchByCountryCode } from 'lib/localStorage'
import type { CheckoutInformation, CheckoutView } from 'types/checkout'
import type * as Contact from 'types/models/contact.model'
import { addressTypes } from 'types/models/contact.model'
import type { Market } from 'types/models/market'
import type { Region } from 'types/models/region'
import type { Environment } from 'types/environment'
import type { Product } from 'types/models/product'

export const useCheckoutStore = defineStore('CheckoutStore', () => {
  const completedViews = useLocalStorage<CheckoutView[]>(COMPLETED_VIEWS, ['cart'])
  const checkoutSteps = useSessionStorage<number[]>(CHECKOUT_STEPS, [])
  const impressions = ref<Product[]>([])
  const information = ref<CheckoutInformation>(DEFAULT_CHECKOUT_INFORMATION)
  const loading = ref<boolean>(false)

  const storefrontStore = useStorefrontStore()
  const orderStore = useOrderStore()
  const cartStore = useCartStore()
  const gtm = useGTM()

  const selectedCountryRegions = computed(() => {
    const data = {
      shipping: [] as Form.Option[],
      billing: [] as Form.Option[],
    }

    addressTypes.forEach((addressType) => {
      const code
        = information.value.address[addressType].country || storefrontStore.currentMarketCountryCode
      const market = storefrontStore.current.markets.find(
        (market: Market) => market.countryCode === code,
      )
      const regions
        = market?.regions.map(
          (region: Region): Form.Option => ({
            value: region.code,
            title: region.name,
          }),
        ) || []

      data[addressType] = regions
    })

    return data
  })

  const setInformationToLocalStorage = (code?: Environment.CountryCode) => {
    const countryCode = code || storefrontStore.currentMarketCountryCode
    patchByCountryCode(countryCode, CHECKOUT_INFO, information.value)
  }

  const setInformationFromLocalStorage = (code?: Environment.CountryCode) => {
    const countryCode = code || storefrontStore.currentMarketCountryCode
    const checkoutInformation = getByCountryCode<CheckoutInformation>(countryCode, CHECKOUT_INFO)

    if (checkoutInformation)
      information.value = checkoutInformation
    else information.value = DEFAULT_CHECKOUT_INFORMATION

    information.value.address.shipping.country = countryCode
    information.value.address.billing.country = countryCode

    setInformationToLocalStorage(countryCode)
  }

  const setContact = (contact: Partial<Contact.Information>) => {
    information.value.contact = {
      ...information.value.contact,
      ...contact,
    }
    setInformationToLocalStorage()
  }

  const setAddress = (key: Contact.AddressType, address: Contact.Address) => {
    information.value.address[key] = address
    setInformationToLocalStorage()
  }

  const removeCompletedView = (name: CheckoutView) => {
    completedViews.value = completedViews.value.filter((view: CheckoutView) => view !== name)
  }

  const removeAddressView = () => {
    orderStore.setSession(undefined)
    removeCompletedView('address')
  }

  const setCompletedView = (name: CheckoutView) => {
    completedViews.value.push(name)
  }

  const setUseShippingForBilling = (value: boolean) => {
    information.value.address.useShippingForBilling = value
    setInformationToLocalStorage()
  }

  const removeInformationFromLocalStorage = (code?: Environment.CountryCode) => {
    const countryCode = code || storefrontStore.currentMarketCountryCode
    patchByCountryCode(countryCode, CHECKOUT_INFO, undefined)
  }

  const reset = () => {
    orderStore.setSession(undefined)
    localStorage.removeItem(ADYEN_CHECKOUT_SESSION)
    // localStorage.removeItem(COMPLETED_VIEWS)
    removeInformationFromLocalStorage()
    completedViews.value = ['cart']
    information.value = DEFAULT_CHECKOUT_INFORMATION
    loading.value = false
  }

  const onPaymentSuccess = async () => {
    // gtm.pushPurchaseEvent() // Fired in the navigation middleware so it can be included in the pageload

    let successfulNewsletterSignUp = false
    const { newsletterSubscription, email } = information.value.contact

    if (newsletterSubscription) {
      try {
        await useFetch('/api/newsletter', {
          method: 'POST',
          body: {
            store: storefrontStore.currentStorefrontCode,
            email,
            countryCode: storefrontStore.currentMarketCountryCode,
            checkout: true,
          },
        })
        successfulNewsletterSignUp = true
        localStorage.setItem(
          'newsletterInteraction',
          JSON.stringify({ type: 'registered', time: new Date() }),
        )
      }
      catch (_) {
        console.warn('Newsletter sign up failed inside of the checkout')
      }
      finally {
        gtm.pushNewsletterEvent(
          {
            eventLabel: successfulNewsletterSignUp ? 'success' : 'fail',
            email: email.toLowerCase(),
            signupSource: 'checkout',
            identifier: 'regular-newsletter',
          },
        )
      }
    }

    // Clear out cart
    cartStore.reset()
    reset()
  }

  const updateCountryCode = (code?: Environment.CountryCode) => {
    setInformationFromLocalStorage(code)
  }

  return {
    checkoutSteps: skipHydrate(checkoutSteps),
    completedViews: skipHydrate(completedViews),
    impressions,
    information,
    loading,
    selectedCountryRegions,
    setInformationToLocalStorage,
    setInformationFromLocalStorage,
    setContact,
    setAddress,
    removeAddressView,
    removeCompletedView,
    setCompletedView,
    setUseShippingForBilling,
    removeInformationFromLocalStorage,
    reset,
    onPaymentSuccess,
    updateCountryCode,
  }
})
