import type { Pinia } from 'pinia'

export default defineNuxtPlugin((nuxtApp) => {
  const $pinia = nuxtApp.$pinia as Pinia
  const storyblokStore = useStoryblokStore($pinia)

  return {
    provide: {
      t: (key: string, data?: { [key: string]: string }) =>
        storyblokStore.getTranslation(key, data),
    },
  }
})
