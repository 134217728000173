import defaultRates from 'data/rates/euro.json'
import type { Environment } from 'types/environment'
import type { RatesResponse } from 'types/rates'

export const useRatesStore = defineStore('RatesStore', () => {
  const rates = ref<RatesResponse>(defaultRates)

  const fetchRates = async () => {
    const data = await $fetch<RatesResponse>('/api/eurocon')
    rates.value = data
  }

  const convert = (amount: number, currencyCode: Environment.Currency['code']) => {
    if (!rates.value)
      throw new Error('Rates not fetched')

    return +(amount / rates.value[currencyCode]).toFixed(2)
  }

  return {
    rates,
    fetchRates,
    convert,
  }
})
