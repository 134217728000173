import { breakpoints, getImageFormat } from 'lib/images'
import { parseURL } from 'lib/url'

interface Attributes {
  width: number
  quality: string | number
  backgroundColor: string
  upscale: boolean
}

export function processImage(url: URL, attr: Attributes): string {
  const background = attr.backgroundColor ? `&bg-color=${attr.backgroundColor}` : ''
  const format = getImageFormat(parseURL(url.href))
  let parsedFormat: string

  switch (true) {
    case format === 'jpg':
    case format === 'pjpg':
    case format === 'pjpeg':
    case format === 'png':
      parsedFormat = 'pjpg'
      break
    default:
      parsedFormat = format || ''
  }

  const quality = attr.quality || (attr.width > 720 ? 60 : 70)
  const upscale = !attr.upscale ? '&disable=upscale' : ''

  return `${url.href}?width=${attr.width}&format=${parsedFormat}&quality=${quality}&auto=webp${background}${upscale}`
}

export function srcSet(url: URL, quality: number, maxWidth?: number) {
  let sizes = breakpoints

  if (maxWidth)
    sizes = sizes.filter(size => size <= maxWidth)

  const output = sizes
    .map(
      width =>
        `${processImage(url, {
          width,
          quality,
          backgroundColor: '',
          upscale: true,
        })} ${width}w`,
    )
    .join(', ')

  return output
}
