export const useDiscountCodeStore = defineStore('DiscountCodeStore', () => {
  const code = ref('')

  // const { $cart } = useNuxtApp()
  // TODO: Check why $cart cannot be deconstructed
  const context = useNuxtApp()

  const cartStore = useCartStore()

  const applyDiscountCode = async (discountCode: string) => {
    try {
      // TODO: Check how this injected function should be used / working
      const cart = await context.$cart.applyDiscountCode(discountCode)
      code.value = discountCode

      cartStore.updateCart(cart)

      return Promise.resolve()
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  return {
    code,
    applyDiscountCode,
  }
})
