export function convertObjectToKeyValueListString(
  obj: Record<string, string | string[]>,
  options?: {
    keySeparator?: string
    arraySeparator?: string
  },
): string {
  const { keySeparator = ':', arraySeparator = ',' } = options || {}

  const keyValueList = Object.entries(obj).map(([key, value]) => `${key}${keySeparator}${value}`)

  return keyValueList.join(arraySeparator)
}
