import type { ConfigGroup, ConfigItem } from '~/data/facets'
import { getQueryKeyFromConfigGroup } from '~/lib/filters'

export function normalizeSidebarFiltersKeys(){
  const storyblokStore = useStoryblokStore()
  const { state } = storeToRefs(storyblokStore)
  const { sidebarFilters } = state.value

  const storyblokSidebarFilters = sidebarFilters?.map(filter => filter.key)
  const normalizedFilters = ['price', ...(storyblokSidebarFilters || [])]
  return normalizedFilters
}

export function normalizeFilterKeys(): readonly string[] {
  const storyblokStore = useStoryblokStore()
  const { state } = storeToRefs(storyblokStore)
  const { facets } = state.value
  const storyblokFiltersKeys = facets?.map(facet => facet.key)
  const normalizedFilterKeys = ['price', ...(storyblokFiltersKeys || [])]
  return normalizedFilterKeys
}
export type FilterMenuKeys = string
export function isFilterMenuKey(key: string): key is FilterMenuKeys {
  return  normalizeFilterKeys().includes(key as FilterMenuKeys)
}

export interface FilterGroup {
  id: FilterMenuKeys
  items: FilterItem[]
  type: 'facet' | 'filter'
  disabled?: boolean
  metadata: Record<string, any> & FilterGroupMetadata
}

interface FilterGroupMetadata {
  translationKey?: string
  inputType?: string | 'radio' | 'checkbox' | 'range'
  inputData?: Record<string, any>
  hidden?: boolean
  queryParam: string
}

export interface FilterItem {
  id: string
  value: number | string | boolean | null | undefined
  disabled: boolean
  metadata?: Record<string, any> & FilterItemMetadata
}

interface FilterItemMetadata {
  translationKey?: string
  hidden?: boolean
  defaultValue?: number | string | boolean | null | undefined
}

export function convertConfigItemToFilterItem(configItem: ConfigItem): FilterItem {
  return {
    id: configItem.id,
    disabled: false,
    value: configItem.value,
    metadata: {
      translationKey: configItem.translationKey ?? configItem.id,
      defaultValue: configItem.value,
    },
  }
}

export function convertConfigGroupToFilterGroup(configGroup: ConfigGroup): FilterGroup {
  return {
    id: configGroup.key as FilterMenuKeys,
    disabled: false,
    type: configGroup.type,
    items: configGroup.items?.map(convertConfigItemToFilterItem) ?? [],
    metadata: {
      translationKey: configGroup.translationKey ?? configGroup.key,
      inputType: configGroup.inputType ?? 'checkbox',
      queryParam: getQueryKeyFromConfigGroup(configGroup),
    },
  }
}

export function convertConfigToFilters(): FilterGroup[] {
  const facetStore = useFacetStore()
  const config = facetStore.DYNAMIC_FACET_CONFIG

  return config.reduce((acc, configGroup) => {
    if (!isFilterMenuKey(configGroup.key) || configGroup.type !== 'filter')
      return acc
    acc.push(convertConfigGroupToFilterGroup(configGroup))
    return acc
  }, [] as FilterGroup[])
}

export type CategoryType = 'category' | 'tag'

// Filter Rules, what filters to show in the UI depending on the category. Configured inside Storyblok
export const filterRules = ['kids', 'no-colors', 'no-filters'] as const
export type FilterRules = (typeof filterRules)[number]

export function excludeAllFilters(rules: FilterRules[]) {
  return rules.includes('no-filters')
}

export function includeFilter(rules: FilterRules[], filterKey: string) {
  const facetStore = useFacetStore()

  const facetConfigGroupSizeAdultKey = facetStore.DYNAMIC_FACET_CONFIG.find(facet => facet.key === 'sizeAdult')?.key
  const facetConfigGroupSizeKidKey = facetStore.DYNAMIC_FACET_CONFIG.find(facet => facet.key === 'sizeKid')?.key
  const facetConfigGroupColorKey = facetStore.DYNAMIC_FACET_CONFIG.find(facet => facet.key === 'color')?.key

  if (filterKey === facetConfigGroupSizeKidKey)
    return rules.includes('kids')

  if (filterKey === facetConfigGroupSizeAdultKey)
    return !rules.includes('kids')

  if (filterKey === facetConfigGroupColorKey)
    return !rules.includes('no-colors')

  return true
}

export const darkColorGroup = ['black', 'brown', 'blue', 'purple'] as const
export type DarkcolorGroup = (typeof darkColorGroup)[number]
export function isDarkColor(color: any): color is DarkcolorGroup {
  return color && darkColorGroup.includes(color)
}
