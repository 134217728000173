import { z } from 'zod'

const StoryCategoryForMenu = z.object({
  full_slug: z.string(),
  id: z.number(),
  uuid: z.string(),
  content: z.object({
    name: z.string(),
  }),
  lang: z.string(),
})

const StoryCategoryForPLP = z.object({
  full_slug: z.string(),
  id: z.number(),
  uuid: z.string(),
  content: z.object({
    name: z.string(),
    id: z.string(),
  }),
})

export const Category = StoryCategoryForMenu.merge(StoryCategoryForPLP)
export type Category = z.infer<typeof Category>

export function normalizeCategory(category: any) {
  const normalizedCategory = Category.safeParse(category)

  if (normalizedCategory.success) {
    return normalizedCategory.data
  }
  else {
    console.error(
      `Problem with category: ${
        category.full_slug
      }. Missing keys: ${normalizedCategory.error.issues.map(issue => issue.path.join(', '))}`,
    )

    return undefined
  }
}

export function normalizeCategories(categories: any[]) {
  return categories.reduce<Category[]>((categories, category) => {
    const normalizedCategory = normalizeCategory(category)

    if (normalizedCategory)
      categories.push(normalizedCategory)

    return categories
  }, [])
}
