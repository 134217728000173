export const adyenTranslations: { [countryCode: string]: string } = {
  ar: 'ar',
  bg: 'bg-BG',
  ca: 'ca-ES',
  cs: 'cs-CZ',
  da: 'da-DK',
  de: 'de-DE',
  el: 'el-GR',
  en: 'en-US',
  es: 'es-ES',
  et: 'et-EE',
  fi: 'fi-FI',
  fr: 'fr-FR',
  hr: 'hr-HR',
  hu: 'hu-HU',
  is: 'is-IS',
  it: 'it-IT',
  ja: 'ja-JP',
  lt: 'lt-LT',
  lv: 'lv-LV',
  nl: 'nl-NL',
  no: 'no-NO',
  pl: 'pl-PL',
  pt: 'pt-BR',
  ro: 'ro-RO',
  ru: 'ru-RU',
  sk: 'sk-SK',
  sl: 'sl-SI',
  sv: 'sv-SE',
  zh: 'zh-CN',
// zhTW: 'zh-TW'
}
