import { checkAndUpdateCountryCode } from '~/lib/storefronts'

export function useCountryTabListener() {
  const { releaseStage } = useRuntimeConfig().public

  if (releaseStage === 'development')
    return

  document.addEventListener('visibilitychange', async () => {
    if (document.visibilityState === 'hidden')
      return

    checkAndUpdateCountryCode()
  })
}
