import { STORES_WITH_OPTIONAL_POSTCODE } from 'configuration/global.configuration'
import type * as Contact from 'types/models/contact.model'

export function isValidContact(contact: Contact.Information) {
  const required: Array<keyof Contact.Information> = ['email', 'telephone', 'agreements']
  return required.every(key => !!contact[key])
}

export function isValidAddress(address: Contact.Address) {
  const code = address.country
  let required: Array<keyof Contact.Address> = [
    'city',
    'firstName',
    'lastName',
    'street0',
    'postCode',
  ]

  if (!code)
    return false

  if (STORES_WITH_OPTIONAL_POSTCODE.includes(code))
    required = required.filter(key => key !== 'postCode')

  if (!required.every(key => !!address[key]))
    return false

  return true
}
