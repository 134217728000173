interface Options {
  sessionStorage?: boolean
}

export function useQueryState(options?: Options) {
  function getQueryState(stateName: string, queryName?: string) {
    const route = useRoute()
    const queryValue = route.query[queryName || stateName]
    return Array.isArray(queryValue) ? queryValue[0] : queryValue
  }

  const getSessionState = (stateName: string) => {
    const value = sessionStorage.getItem(stateName)
    return value
  }

  const setQueryState = (stateName: string, queryName?: string) => {
    const queryValue = getQueryState(stateName, queryName)

    if (options?.sessionStorage) {
      if (queryValue) {
        sessionStorage.setItem(stateName, queryValue)
      }
      else {
        const sessionValue = getSessionState(stateName)
        return useState(stateName, () => sessionValue)
      }
    }

    useState(stateName, () => queryValue)
  }

  return {
    setQueryState,
  }
}
