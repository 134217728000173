import { string } from 'zod'
import { useQueryState } from './useQueryState'
import type { Variables } from '~/contracts/ProductRepositoryContract'

const SEGMENT_VARIABLES = 'segment-variables'

type VariablesPerWidget = Record<string, Variables>

export function useSegment() {
  const segments = computed(() => {
    return useStoryblokStore().state.segments
  })

  const segmentVariables = useState<VariablesPerWidget>(SEGMENT_VARIABLES)
  const activeUtmCampaign = useState<string>('utm_campaign')
  const activeUtmMedium = useState<string>('utm_medium')
  const activeUtmContent = useState<string>('utm_content')
  const hasSubscribed = useSessionStorage<boolean>('has_subscribed', false)

  const activeSegment = computed(() => {
    return segments.value?.find(segment => segment.utmCampaigns.includes(activeUtmCampaign.value) || segment.utmContents.includes(activeUtmContent.value))
  })

  const activeSegmentId = computed(() => {
    return activeSegment.value?.id
  })
  const isSubscribed = computed(() => {
    return activeUtmMedium.value === 'email' || hasSubscribed.value
  })

  const disableRegularNewsletter = computed(() => {
    return Boolean(activeSegment.value?.hideNewsletter)
  })

  function setSegmentVariables(widgetId: string, variables: Variables) {
    segmentVariables.value[widgetId] = variables
    sessionStorage.setItem(SEGMENT_VARIABLES, JSON.stringify(segmentVariables.value))
  }

  function loadSegmentVariables() {
    const value = sessionStorage.getItem(SEGMENT_VARIABLES)
    segmentVariables.value = value ? JSON.parse(value) : {}
  }

  function getSegmentVariables(widgetId: string) {
    return segmentVariables.value[widgetId]
  }

  function loadUtmVariables() {
    const { setQueryState } = useQueryState({ sessionStorage: true })
    setQueryState('utm_campaign')
    setQueryState('utm_medium')
    setQueryState('utm_content')
  }

  function loadSegment() {
    loadSegmentVariables()
    loadUtmVariables()
  }

  function setSubscribed(segmentId: string) {
    if (segmentId !== activeSegmentId.value)
      return
    hasSubscribed.value = true
  }

  return {
    activeSegment,
    activeSegmentId,
    isSubscribed,
    disableRegularNewsletter,

    setSegmentVariables,
    loadSegment,
    getSegmentVariables,
    setSubscribed,
  }
}
