export function isElement(element: any): element is HTMLElement {
  return element && Reflect.has(element, 'nodeName')
}

export function isElementType<T>(element: any, type: 'div' | 'input' | 'textarea' | 'select' | 'img' | 'picture' | 'form' | 'li' | 'button' | 'a'): element is T {
  if (!isElement(element))
    return false
  return element.nodeName === type.toUpperCase()
}

export function isLiElementType(childItem: ChildNode): childItem is HTMLLIElement {
  return isElementType<HTMLLIElement>(childItem, 'li')
}
