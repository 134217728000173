import { withQuery } from 'ufo'
import type { RouteLocationNormalizedLoaded } from '#vue-router'
import { stripTrailingSlashes } from '~/lib/strings'
import { parseMeta } from '~/lib/routing'

const allowedCheckoutReturnRoutes = ['storyblok', 'section', 'search', 'homepage']

export const useRouteStore = defineStore('RouteStore', () => {
  const router = useRouter()
  const route = useRoute()

  /* Keep track of the last route */
  const lastRouteLocation = ref<RouteLocationNormalizedLoaded>()
  const lastRoute = computed<string | undefined>(() => stripTrailingSlashes(lastRouteLocation.value?.fullPath || ''))
  const lastRouteOrHomepage = computed<string>(() => lastRoute.value || withQuery(`/${route.params.storefront}`, route.query))
  router.afterEach((_, from) => { lastRouteLocation.value = from })

  /* When closing checkout, we only allow to go back to certain routes */
  const checkoutReturnRouteLocation = ref<RouteLocationNormalizedLoaded>()
  const checkoutReturnRoute = computed<string>(() => stripTrailingSlashes(checkoutReturnRouteLocation.value?.fullPath || ''))
  router.afterEach((_, from) => {
    if (allowedCheckoutReturnRoutes.includes(parseMeta(from).context || ''))
      checkoutReturnRouteLocation.value = from
  })

  /* Last route conversions */

  return {
    lastRouteLocation,
    lastRoute,
    lastRouteOrHomepage,

    checkoutReturnRouteLocation,
    checkoutReturnRoute,
  }
})
