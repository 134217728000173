<script setup lang="ts">
import type { UILink } from '~/types/storyblok'
import { useSpeechBubbleStore } from '~/stores/speechBubble'
import { focusNextDOMElement } from '~/lib/helpers/focusNextDOMElement.js'

const emit = defineEmits<{ (e: 'submenuOpen', state: boolean): void }>()

const storyblokStore = useStoryblokStore()
const { state: storyblok } = storeToRefs(storyblokStore)
const { links: headerNavbarLinks } = useHeaderNavbarItems()
const speechBubbleStore = useSpeechBubbleStore()

const isSearchExpanded = ref(false)
const isLogoCollapsed = ref(false)
const isSubmenuOpen = ref(false)
const categorySelected = ref<UILink | null>(null)
const { disableRegularNewsletter } = useSegment()

const { links: submenuLinks } = useSubmenuLinks(categorySelected)

const route = useRoute()

function setSearchExpanded() {
  isSearchExpanded.value = true
  isLogoCollapsed.value = true
}

function setLogoCollapsed() {
  isLogoCollapsed.value = true
}

function openSubmenu(link: UILink) {
  categorySelected.value = link
  isSubmenuOpen.value = true
  speechBubbleStore.setSpeechBubble('menu-expanded', speechBubbleStore.getStates()?.menuExpanded)

  emit('submenuOpen', true)
}

function closeSubmenu() {
  isSubmenuOpen.value = false
  speechBubbleStore.hideSpeechBubble()

  emit('submenuOpen', false)
}

const navbarLeftHeight = ref(0)

function setNavbarHeight() {
  const navbarLeft = document.querySelector('div.navbar__left')
  if (!navbarLeft)
    return

  nextTick(() => {
    navbarLeftHeight.value = navbarLeft?.clientHeight ?? 48
    document.body.style.setProperty('--navbar-left-height', `${navbarLeftHeight.value}px`)
  })
}

function onResize() {
  setNavbarHeight()
}

onMounted(() => {
  window.addEventListener('resize', onResize)
  onResize()
})

onUnmounted(() => {
  window.removeEventListener('resize', onResize)
})
</script>

<template>
  <nav class="navbar" :aria-label="$t('navigationBar')" data-test="navbar">
    <div
      class="navbar__left" :class="[
        {
          'collapsed': isSearchExpanded,
          'navbar__left--without-margin': isSearchExpanded,
          'navbar__left--without-radius': isSubmenuOpen,
        },
      ]"
      @mouseleave="closeSubmenu()"
    >
      <div class="navbar__left--top" :class="[{ 'navbar__left--with-submenu': isSubmenuOpen }]">
        <HeaderLogo
          :is-logo-collapsed="isLogoCollapsed"
          device="desktop"
          @logo-collapse="setLogoCollapsed()"
        />
        <ul class="navbar__links" :class="[{ 'navbar__links--hidden': isSearchExpanded }]">
          <li
            v-for="(link, index) in headerNavbarLinks"
            :key="index"
            class="navbar__links-item"
            @mouseover="openSubmenu(link)"
          >
            <NuxtLink
              class="navbar__links-link"
              :to="$url(link.url)"
              :target="link.url.startsWith('http') ? '_blank' : '_self'"
              @click="closeSubmenu"
            >
              <span
                class="text-underline" :class="[
                  {
                    active: route.path.startsWith($url(link.url)),
                  },
                ]"
              >
                {{ link.text }}
              </span>
            </NuxtLink>
          </li>
        </ul>
      </div>
      <div v-if="isSubmenuOpen" class="navbar__left--bottom">
        <HeaderSubmenuLinks
          class="submenu"
          :category-selected="categorySelected?.text ?? ''"
          :navigation-items="submenuLinks ?? []"
          direction="row"
          device="desktop"
          @redirect="closeSubmenu"
        />
        <HeaderCountryAndSocial />
        <Newsletter
          v-if="storyblok.newsletter && !disableRegularNewsletter"
          :newsletter="storyblok.newsletter"
          background-color="var(--pink)"
          context="menu"
        />
      </div>
    </div>

    <div class="navbar__right">
      <HeaderSearch
        :expanded="true"
        :expand-on-focus="true"
        :has-standard-width="isLogoCollapsed"
        device="desktop"
        @search-expand="setSearchExpanded()"
        @search-collapse="isSearchExpanded = false"
        @focus-next-element="focusNextDOMElement($event, '[data-accessibility=cart]')"
      />
      <div class="wrapper">
        <HeaderCartLink data-accessibility="cart" />
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';

.navbar {
  display: none;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  &__left {
    position: relative;
    display: flex;
    align-items: center;
    background-color: var(--gray);
    border-radius: 2px;
    margin: 1.5rem 2rem;
    height: 4.8rem;
    min-width: 20%;
    transition: min-width 0.5s ease-out;

    &--without-radius {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &--without-margin {
      margin-right: 0;
    }

    &--with-submenu {
      width: 70vw;
      max-width: 86rem;
    }

    &--top {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 2rem;
    }

    &--bottom {
      position: absolute;
      width: 100%;
      top: var(--navbar-left-height);
      background-color: var(--gray);
      border-bottom-left-radius: 0.2rem;
      border-bottom-right-radius: 0.2rem;
      overflow: hidden;
    }
  }

  &__links {
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    transition: max-width 0.5s ease-out;

    &--hidden {
      max-width: 0;
    }

    &-item {
      &:first-child {
        margin-left: 2.4rem;
      }
    }

    &-link {
      padding: 1rem 2rem;
      display: inline-block;
      line-height: 1.5;
      outline-offset: -2px;
    }
  }

  .text-underline {
    &.active,
    &:hover {
      background-image: url('/icons/wavyLine.svg');
      position: relative;
      background-size: 8rem;
      background-repeat: repeat-x;
      background-position-y: bottom;
    }
  }
  .text-underline {
    display: inline-block;
  }
  &__right {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem 2rem;
    align-self: flex-end;
    align-self: center;
  }

  @media (min-width: $tablet) {
    display: flex;
    width: -webkit-fill-available;
  }
}

.collapsed {
  min-width: 6rem;
}

.submenu {
  display: flex;
  flex-direction: column;
}

.submenu.nav {
  overflow-y: hidden;
}

.cart-btn {
  grid-area: cart;
  &:focus-visible {
    z-index:2;
  }

  &:hover {
    text-decoration: unset;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
}
</style>
