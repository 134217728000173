import { setupGameLoop } from '~/lib/gameloop'
import { pickRandomUnique, randomBetween } from '~/lib/random'

interface MousePosition { x: number, y: number }

interface GameLoopState {
  canvas?: HTMLCanvasElement
  canvasCtx?: CanvasRenderingContext2D
  config: BouncingBallsConfig
  balls: Ball[]
  mousePos?: MousePosition
}

export interface BouncingBallsConfig {
  /** The speed at which the ball falls down */
  gravity: number
  /** The speed at which the ball bounces back up */
  bounce: number
  /** At what Y velocity to stop bouncing */
  bounceStopThreshold: number
  /** The friction of the ball while rolling */
  friction: number
  /** At what X velocity to stop rolling */
  rollStopThreshold: number
  /** Size of the balls */
  radius: { min: number, max: number }
  /** x speed of the balls */
  xVelocity: { min: number, max: number }
  /** y speed of the balls */
  yVelocity: { min: number, max: number }
  /** Amount of balls */
  amountOfBalls: number
  /** Available colors to pick from */
  colors: string[]
  /** 0.25 = spawn in top 25% of the screen. 0.50 = top 50% and 1 = anywhere */
  yStartPos: number
  /** Push the balls around with your mouse */
  enableMousePush: boolean
  /** The power the mouse is pushing the balls around with */
  pushPower: number
}

const defaultBouncingBallsConfig: BouncingBallsConfig = {
  gravity: 0.15,
  // gravity: 0,
  bounce: 0.7,
  bounceStopThreshold: 1.6,
  friction: 0.05,
  rollStopThreshold: 0.4,
  radius: { min: 100, max: 100 },
  xVelocity: ({ min: -6, max: 6 }),
  yVelocity: ({ min: -7, max: 0 }),
  amountOfBalls: 10,
  colors: [
    '#fae800',
    '#00b852',
    '#ff8114',
    '#ff410d',
    '#fe96b5',
    // '#0066ee', // Disabled blue for better contrast
  ],
  yStartPos: 0.5,
  enableMousePush: false,
  pushPower: 1,
}

interface Ball {
  x: number
  y: number
  radius: number
  color: string
  velocity: {
    x: number
    y: number
  }
  pushed: boolean
}

function updateCanvasSize(canvas: HTMLCanvasElement) {
  const ratio = window.devicePixelRatio
  const { width, height } = canvas.getBoundingClientRect()
  canvas.width = width * ratio
  canvas.height = height * ratio
}

function initBalls(canvas: HTMLCanvasElement, config: BouncingBallsConfig): Ball[] {
  const ratio = window.devicePixelRatio
  const balls: Ball[] = []
  const ballColors = pickRandomUnique(config.colors, config.amountOfBalls)

  for (let i = 0; i < config.amountOfBalls; i++) {
    const radius = randomBetween(config.radius.min, config.radius.max) * ratio

    balls.push(
      {
        x: randomBetween(radius, canvas.width - radius),
        y: randomBetween(radius, (canvas.height * config.yStartPos) - radius),
        radius,
        color: ballColors[i],
        velocity: {
          x: randomBetween(config.xVelocity.min, config.xVelocity.max),
          y: randomBetween(config.yVelocity.min, config.yVelocity.max),
        },
        pushed: false,
      },
    )
  }

  return balls
}

function update({ canvas, canvasCtx, balls, config, mousePos }: GameLoopState) {
  if (!canvas || !canvasCtx)
    return

  const moveBall = (ball: Ball, gravity: number) => {
    // Move
    ball.x += ball.velocity.x * devicePixelRatio
    ball.y += ball.velocity.y * devicePixelRatio

    // Adds gravity - when ball is not at bottom
    if (ball.y !== canvas.height - ball.radius)
      ball.velocity.y += gravity
  }

  const handleWallHit = (ball: Ball) => {
    // Ball hits wall - Change direction
    if (ball.x + ball.radius > canvas.width) {
      ball.velocity.x *= -1
      ball.x = canvas.width - ball.radius
    }
    else if (ball.x - ball.radius < 0) {
      ball.velocity.x *= -1
      ball.x = ball.radius
    }
  }

  const handleFloorHit = (ball: Ball, bounce: number, bounceStopThreshold: number, friction: number, rollStopThreshold: number) => {
    // Do nothing if ball is not at the bottom
    if (ball.y + ball.radius < canvas.height)
      return

    // Reset ball to correct y position
    ball.y = canvas.height - ball.radius

    // Stop bouncing after a certrain threshold
    if (!ball.pushed && ball.velocity.y <= bounceStopThreshold)
      ball.velocity.y = 0

    // Adds bounce - Invert velocity
    ball.velocity.y *= -bounce

    // Adds roll friction
    if (ball.velocity.x > 0)
      ball.velocity.x = ball.velocity.x - friction
    else if (ball.velocity.x < 0)
      ball.velocity.x = ball.velocity.x + friction

    // Stop rolling after a certain treshold
    if (!ball.pushed && Math.abs(ball.velocity.x) < rollStopThreshold)
      ball.velocity.x = 0
  }

  const handlePush = (ball: Ball, enabled: boolean, mousePos?: MousePosition) => {
    if (!enabled || !mousePos)
      return

    // Calculate distance between ball and mouse
    const ballPos = { x: ball.x, y: ball.y }
    const distance = Math.sqrt(
      (mousePos.x - ballPos.x) ** 2 + (mousePos.y - ballPos.y) ** 2,
    )

    // Only push if mouse is close enough to the ball
    ball.pushed = distance <= ball.radius
    if (!ball.pushed)
      return

    // Add more velocity based on how close the mouse is
    const xPower = (ballPos.x - mousePos.x) / ball.radius
    const yPower = (ballPos.y - mousePos.y) / ball.radius
    ball.velocity.x = ball.velocity.x + (xPower * config.pushPower)
    ball.velocity.y = ball.velocity.y + (yPower * config.pushPower)
  }

  const { gravity, bounce, friction, bounceStopThreshold, rollStopThreshold, enableMousePush } = config
  balls.forEach((ball) => {
    handlePush(ball, enableMousePush, mousePos)
    moveBall(ball, gravity)
    handleWallHit(ball)
    handleFloorHit(ball, bounce, bounceStopThreshold, friction, rollStopThreshold)
  })
}

function draw({ canvas, canvasCtx, balls }: GameLoopState) {
  if (!canvas || !canvasCtx)
    return

  const drawBall = ({ x, y, radius, color }: Ball) => {
    // Draw balls
    canvasCtx.beginPath()
    canvasCtx.arc(x, y, radius, 0, Math.PI * 2, false)
    canvasCtx.fillStyle = color
    canvasCtx.fill()
    canvasCtx.closePath()
  }

  // Clear canvas
  canvasCtx.clearRect(0, 0, canvas.width, canvas.height)

  // Draw balls
  balls.forEach(drawBall)
}

export function useBouncingBalls(
  _canvas: Ref<HTMLCanvasElement | undefined>,
  _config?: Ref<Partial<BouncingBallsConfig>>,
) {
  const canvasEl = computed(() => _canvas.value)
  const canvasCtx = computed(() => _canvas.value?.getContext('2d'))
  const config = computed<BouncingBallsConfig>(() => ({
    ...defaultBouncingBallsConfig,
    ...(_config ? _config.value : {}),
  }))

  const { startGameLoop, stopGameLoop, isRunning, forceDraw, updateGameLoopState, resetGameLoopState } = setupGameLoop<GameLoopState>(
    60,
    update,
    draw,
    { balls: [] as Ball[], config: config.value },
  )

  function setup() {
    const wasRunning = isRunning()

    cleanup()

    if (!canvasEl.value || !canvasCtx.value)
      return

    updateCanvasSize(canvasEl.value)
    updateGameLoopState({
      canvas: canvasEl.value,
      canvasCtx: canvasCtx.value,
      balls: initBalls(canvasEl.value, config.value),
    })
    forceDraw()

    if (wasRunning)
      startGameLoop()

    if (config.value.enableMousePush)
      window.addEventListener('mousemove', onMouseMove, { passive: true })
  }

  function cleanup() {
    stopGameLoop()
    resetGameLoopState()
    window.removeEventListener('mousemove', onMouseMove)
  }

  function onMouseMove(e: MouseEvent) {
    if (!canvasEl.value)
      return

    const rect = canvasEl.value!.getBoundingClientRect()
    const mousePos = {
      x: (e.clientX - rect.left) * window.devicePixelRatio,
      y: (e.clientY - rect.top) * window.devicePixelRatio,
    }
    updateGameLoopState({ mousePos })
  }

  watch(canvasEl, () => setup())
  watch(config, () => setup())
  onUnmounted(() => cleanup())
  useResizeObserver(canvasEl, () => setup())

  return {
    start: () => startGameLoop(),
    reset: () => {
      stopGameLoop()
      setup()
    },
    restart: () => setup(),
  }
}
