import { randomUUID } from 'uncrypto'
import { USER_ID_COOKIE } from '~/configuration/global.configuration'
import { HALF_A_YEAR_IN_MILLISECONDS } from '~/data/time/milliseconds'

export default defineNuxtPlugin(async () => {
  const cookie = useCookie(USER_ID_COOKIE, {
    maxAge: HALF_A_YEAR_IN_MILLISECONDS / 1000,
  })
  if (cookie.value)
    return

  cookie.value = randomUUID()
})
