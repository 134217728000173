import type { AxiosInstance } from 'axios'
import Axios from 'axios'
import consola from 'consola'
import { terminalColors } from './color'

export function createRepositoryLogger(name: string, api: AxiosInstance): AxiosInstance {
  if (process.client)
    return api

  const { LOG_TYPES, LOG_LEVEL, BUILD_CONTEXT } = process.env
  if (LOG_TYPES?.split(',').includes('repositories') === false)
    return api

  const colors = Object.values(terminalColors.rainbow)
  const color = colors[Math.floor(Math.random() * colors.length)]

  if (LOG_LEVEL === 'verbose') {
    api.interceptors.request.use((config) => {
      consola.info(
        BUILD_CONTEXT === 'local' ? color : '',
        name,
        BUILD_CONTEXT === 'local' ? terminalColors.reset : '',
        `${config.method?.toUpperCase()}: ${Axios.getUri(config)}`,
        config.data ? JSON.stringify(config.data) : '',
      )

      return config
    })
  }

  return api
}
