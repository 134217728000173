// TODO: Move to a better filename

export function getItemsCountWithQuantity(items: any[], prop = 'quantity'): number {
  return items.reduce((sum, item) => sum + getNestedProperty(item, prop), 0)
}

export function getItemsPrice(items: any[], prop = 'price', qtyProp = 'quantity'): number {
  return items.reduce((sum, item) => {
    const itemPrice = getNestedProperty(item, prop)
    const itemQuantity = getNestedProperty(item, qtyProp)

    return sum + itemPrice * itemQuantity
  }, 0)
}

function getNestedProperty(obj: any, path: string): any {
  const pathArray = path.split('.')
  const property = pathArray.reduce((acc, prop) => acc[prop], obj)

  return property
}
