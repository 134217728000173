import { stripStorefrontCodeFromUrl } from '~/lib/url/stripStorefrontCode'

export function useHeaderNavbarItems() {
  const storyblokStore = useStoryblokStore()
  const storefrontStore = useStorefrontStore()
  const { currentStorefrontCode } = storeToRefs(storefrontStore)

  const links = computed(() => {
    const alternateMenuSplitTestCookie = useSplitTest('NAV_01_US_ALL')
    const isAlternateMenu = alternateMenuSplitTestCookie.value === 'variant1' && currentStorefrontCode.value === 'us'
    const links = isAlternateMenu ? storyblokStore.state.headerNavbarAlternate : storyblokStore.state.headerNavbar
    return links?.map(link => ({
      ...link,
      url: stripStorefrontCodeFromUrl(link.url).urlWithoutStorefrontCode,
    }))
  })

  return {
    links,
  }
}
