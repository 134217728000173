import type { UILink } from '~/types/storyblok'

export function useSubmenuLinks(category: Ref<UILink | null>) {
  const { submenus } = useHeaderNavbarSubmenus()

  const links = computed(() => {
    const id = category?.value?._uid ?? ''
    const key = `${id}`
    return submenus.value?.[key]
  })

  return {
    links,
  }
}
