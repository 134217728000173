import { defineStore } from 'pinia'

import { ITEMS_PER_FILTER_REQUEST } from 'configuration/global.configuration'
import { getRouteTypeFromURL } from '~/lib/routing'
import type { SearchOptions, SearchResponse, Sort } from '~/contracts/SearchRepositoryContract'
import type { CategoryType, FilterGroup } from '~/types/models/filter.model'
import { FACET_CONFIG } from '~/data/facets'

export const useFacetStore = defineStore('FacetStore', () => {
  const filterBarDisabled = ref(false)
  const loadingFacets = ref(false)

  const activeCategoryId = ref<string | undefined>(undefined)
  const activeCategoryType = ref<CategoryType | undefined>(undefined)

  const { $facets } = useNuxtApp()

  const DYNAMIC_FACET_CONFIG = computed(() => {
    const storyblokStore = useStoryblokStore()
    const { state } = storeToRefs(storyblokStore)
    const { facets: normalizedStoryblokFacets } = state.value
    const normalizedFacets = [...FACET_CONFIG, ...(normalizedStoryblokFacets || [])]
    return normalizedFacets
  })

  // Methods
  function setCategory(categoryId?: string, type: CategoryType = 'category') {
    if (!categoryId) {
      activeCategoryId.value = undefined
      activeCategoryType.value = undefined

      return
    }

    if (activeCategoryId.value === categoryId)
      return

    activeCategoryId.value = categoryId
    activeCategoryType.value = type
  }

  // actions
  const fetch = async (options?: {
    page?: number
    categoryId?: string
    categoryType?: CategoryType
    filters?: FilterGroup[]
    searchTerm?: string
    facetsOnly?: boolean
    limit?: number
    sort?: Sort
  }): Promise<SearchResponse> => {
    const defaults = {
      categoryId: undefined,
      categoryType: undefined,
      page: 1,
      searchTerm: undefined,
      facetsOnly: false,
      limit: ITEMS_PER_FILTER_REQUEST,
    }

    const {
      categoryId,
      categoryType,
      page,
      searchTerm,
      facetsOnly,
      limit,
      sort,
    } = Object.assign({}, defaults, options)

    const facets = options?.filters?.filter(filter => filter.type === 'facet') || []
    const filters = options?.filters?.filter(filter => filter.type === 'filter') || []

    const pageType = getRouteTypeFromURL(useRoute())

    const searchOptions: SearchOptions = {
      facets,
      limit,
      offset: (page - 1) * ITEMS_PER_FILTER_REQUEST,
    }

    if (pageType === 'category' && categoryType && categoryId) {
      searchOptions.categoryFacet = {
        category: categoryId,
        type: categoryType,
      }
    }

    if (pageType === 'search' && searchTerm)
      searchOptions.searchTerm = searchTerm

    if (facetsOnly)
      searchOptions.filtersOnly = true

    if (sort)
      searchOptions.sort = sort

    if (filters.length > 0)
      searchOptions.filters = filters

    try {
      loadingFacets.value = true
      const data = await $facets.searchProducts(searchOptions, searchTerm)

      return Promise.resolve(data)
    }
    catch (e) {
      console.warn('Failed to fetch products', e)
      return Promise.reject(e)
    }
    finally {
      loadingFacets.value = false
    }
  }

  async function fetchFacetsOnly(options?: {
    categoryId?: string
    categoryType?: CategoryType
    filters?: FilterGroup[]
    searchTerm?: string
    filtersOnly?: boolean
  }) {
    const defaults = {
      categoryId: activeCategoryId.value,
      categoryType: activeCategoryType.value,
      searchTerm: useRoute().query.term as string,
      filtersOnly: true,
    }

    const { categoryId, categoryType, searchTerm, filtersOnly }
      = Object.assign({}, defaults, options)

    try {
      loadingFacets.value = true

      const pageType = getRouteTypeFromURL(useRoute())

      const facets = options?.filters?.filter(filter => filter.type === 'facet') || []
      const filters = options?.filters?.filter(filter => filter.type === 'filter') || []

      const searchOptions: SearchOptions = {
        facets,
        filters,
        filtersOnly,
      }

      if (pageType === 'category' && categoryType && categoryId) {
        searchOptions.categoryFacet = {
          category: categoryId,
          type: categoryType,
        }
      }

      if (pageType === 'search' && searchTerm)
        searchOptions.searchTerm = searchTerm

      const data = await $facets.searchProducts(searchOptions)

      return Promise.resolve(data)
    }
    catch (e) {
      console.warn('Failed to fetch available filters', e)
      return Promise.reject(e)
    }
    finally {
      loadingFacets.value = false
    }
  }

  const fetchAllAvailableFiltersForCategory = async (
    categoryId: string,
    categoryType: CategoryType,
  ) => {
    try {
      loadingFacets.value = true

      const categoryFacet = {
        category: categoryId,
        type: categoryType,
      }

      const data = await $facets.searchProducts({
        filtersOnly: true,
        categoryFacet,
      })

      return Promise.resolve(data)
    }
    catch (e) {
      console.warn('Failed to fetch available filters', e)
      return Promise.reject(e)
    }
    finally {
      loadingFacets.value = false
    }
  }

  return {
    // State
    filterBarDisabled,
    loadingFacets,
    activeCategoryId,
    activeCategoryType,
    DYNAMIC_FACET_CONFIG,

    // Methods
    setCategory,

    // actions
    fetch,
    fetchFacetsOnly,
    fetchAllAvailableFiltersForCategory,
  }
})
