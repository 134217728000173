import type { Pinia } from 'pinia'

export default defineNuxtPlugin({
  name: 'kindly',
  parallel: true,
  dependsOn: ['storefront'],
  async setup(nuxtApp) {
    const config = useRuntimeConfig()

    const $pinia = nuxtApp.$pinia as Pinia

    const currentStoreFront = useStorefrontStore($pinia).current
    const currentContentLocale = currentStoreFront.contentLocale

    const language = currentContentLocale === 'default' ? 'en' : currentContentLocale.split('-')[0]

    const script = document.createElement('script')
    script.id = 'kindly-chat'
    script.dataset.botKey = config.public.kindlyBotId
    /* BE AWARE - this attribute can change in the future which will impact this style. The style should only move the questions mark image up a bit on smaller devices
    which shouldn't impact the solution in any way only the UX.
  */
    script.dataset.styles
      = '[data-cy="chat-bubble-logo-container"]{ background-size: cover !important; background-position: center !important, color: red }'
    script.dataset.shadowDom = ''
    script.defer = true
    script.type = 'module'
    script.src = 'https://chat.kindlycdn.com/kindly-chat.js'
    document.body.appendChild(script)

    window.kindlyOptions = {
      bubbleAvatar: 'https://i.imgur.com/sZigSAn.png',
      language,
    }
  },
})
