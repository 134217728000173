import { checkoutUrl } from 'lib/routing'
import { url } from 'lib/url'

export default defineNuxtPlugin({
  name: 'routing',
  parallel: true,
  dependsOn: [],
  async setup(nuxtApp) {
    nuxtApp.provide('url', url)
    nuxtApp.provide('checkoutUrl', checkoutUrl)
  },
})
