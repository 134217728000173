import { setWindowHeight } from 'lib/layout'

type Device = 'mobile' | 'tablet' | 'desktop'

export const useMediaStore = defineStore('MediaStore', () => {
  const device = ref<Device>('desktop')
  const pageOverlay = ref('')
  const prefersReducedMotion = ref(false)

  const isMobile = computed(() => ['mobile', 'tablet'].includes(device.value))
  const isDesktop = computed(() => device.value === 'desktop')

  function watchMedia() {
    const desktop = window.matchMedia('(min-width: 1440px)')
    const tablet = window.matchMedia('(min-width: 960px) and (max-width: 1439px)')
    const mobile = window.matchMedia('(max-width: 959px)')
    const prefersReducedMotionCheck = window.matchMedia('(prefers-reduced-motion: reduce)')

    if (mobile.matches)
      device.value = 'mobile'
    if (tablet.matches)
      device.value = 'tablet'
    if (desktop.matches)
      device.value = 'desktop'
    if (prefersReducedMotionCheck.matches)
      prefersReducedMotion.value = true

    mobile.onchange = (change: MediaQueryListEvent) => {
      change.matches && (device.value = 'mobile')
    }
    tablet.onchange = (change: MediaQueryListEvent) => {
      change.matches && (device.value = 'tablet')
    }
    desktop.onchange = (change: MediaQueryListEvent) => {
      change.matches && (device.value = 'desktop')
    }
    prefersReducedMotionCheck.onchange = (change: MediaQueryListEvent) => {
      change.matches && (prefersReducedMotion.value = true)
    }
    window.addEventListener('resize', setWindowHeight)
  }

  const setPageOverlay = (name: '' | 'snow') => {
    if (prefersReducedMotion.value)
      pageOverlay.value = ''
    else
      pageOverlay.value = name
  }

  return { pageOverlay, device, isMobile, isDesktop, watchMedia, setPageOverlay }
})
