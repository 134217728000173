import type { Asset, SelectField, TextField } from '../shared'
import type Storyblok from '~/types/vendors/storyblok'
import { normalizeRichtext } from '~/utils/storyblok/richtext'

export interface SegmentedPopupResponse {
  _uid: string
  segment_id: string
  component: 'segmented-popup'
  _editable: string
  title?: string
  cta_label: string
  description: string
  form: (TextField | SelectField)[]
  mobile_asset: Asset
  mobile_alt_text: string
  desktop_asset: Asset
  desktop_alt_text: string
  legal: Storyblok.Richtext
  success_message: Storyblok.Richtext
  error_message: Storyblok.Richtext
  popup_identifier: string
}

export interface SegmentedPopup {
  title: string
  segmentId: string
  description: string
  ctaLabel: string
  form: (TextField | SelectField)[]
  mobileAsset: string
  mobileAltText: string
  desktopAsset: string
  desktopAltText: string
  legal: Storyblok.Richtext
  successMessage: Storyblok.Richtext
  errorMessage: Storyblok.Richtext
  popupIdentifier: string
}

export function normalizeSegmentedPopup(segmentedPopups?: Storyblok.Story<SegmentedPopupResponse>[]): SegmentedPopup[] {
  const normalizedSegmentedPopups = segmentedPopups?.map((segmentedPopup) => {
    const { content } = segmentedPopup ?? {}
    const { legal, success_message, error_message } = content ?? {}
    return {
      segmentId: content?.segment_id || '',
      legal: normalizeRichtext(legal),
      title: content?.title?.trim() || '',
      ctaLabel: content?.cta_label?.trim() || '',
      form: content?.form || [],
      mobileAsset: content?.mobile_asset?.filename || '',
      mobileAltText: content?.mobile_alt_text?.trim() || '',
      desktopAsset: content?.desktop_asset?.filename || '',
      desktopAltText: content?.desktop_alt_text?.trim() || '',
      description: content?.description?.trim() || '',
      successMessage: normalizeRichtext(success_message),
      errorMessage: normalizeRichtext(error_message),
      popupIdentifier: content?.popup_identifier || '',
    }
  })

  return normalizedSegmentedPopups ?? []
}
