import type { FilterGroup } from '~/types/models/filter.model'

// Assign facets to variables $filter_1 to $filter_6 where the value is the facetValue, and this for every value in the facet. e.g. $filter_1 = [cotton, polyester]
export function assignFacetVariables(facets: FilterGroup[]) {
  const facetVariables: Record<string, string[]> = {}

  facets.forEach((facet, index) => {
    const facetValues = facet.items

    facetValues.forEach((facetValue) => {
      const keyName = `$filter_${index + 1}`
      const facetVariable = `${facetValue.id}`
      if (!facetVariables[keyName])
        facetVariables[keyName] = []

      facetVariables[keyName]!.push(facetVariable)
    })
  })

  return facetVariables
}
