<script setup lang="ts">
import xss from 'xss'

defineProps({
  error: {
    type: Object,
    required: true,
  },
})

useHead({
  title: 'Happy Socks',
})

const router = useRouter()

router.currentRoute.value.params.isError = 'true'
</script>

<template>
  <div class="layout">
    <HeaderMain />
    <main class="error-page" :data-error-code="error.statusCode">
      <p class="title">
        {{ $t('cantFindIt') }}
      </p>

      <p v-html="xss($t('errorDescription'))" />

      <NuxtLink class="link" :to="$url('/')">
        {{ $t('backToTheStartPage') }}
      </NuxtLink>

      <p class="message" hidden aria-hidden="true">
        {{ error.message }}
      </p>
      <p class="path" hidden aria-hidden="true">
        {{ error.path }}
      </p>
    </main>
    <Footer />
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';
@import 'assets/scss/typography/headings';
@import 'assets/scss/typography/body';

.layout {
  min-height: 100vh;
  display: grid;
}

.error-page {
  display: flex;
  flex-direction: column;
  padding-top: var(--header-main-height);
  margin: 0 1.6rem;
}

.title {
  @include h4;
  margin-bottom: 1.6rem;
}

.description {
  @include body1;
}

.link {
  text-decoration: underline;
  display: block;
  margin-top: 2.4rem;
  margin-bottom: 4rem;
}

.products-carousel {
  margin: 2rem 0;
  margin-top: 8rem;

  :deep(.product-card .details) {
    padding: 0;
  }
}

@media (min-width: $large-breakpoint) {
  .title {
    @include h3;
  }

  .products-carousel {
    margin-top: 9.5rem;
  }
}
</style>
