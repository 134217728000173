import type Storyblok from '~/types/vendors/storyblok'

export interface PdpFeatureIcon {
  _uid: string
  icon: PdpIcon
  title: string
  component: 'pdp-list-icon'
  _editable: string
}

export interface PdpIcon {
  id: string
  alt: string
  name: string
  focus: null
  title: string
  filename: string
  copyright: string
  fieldtype: string
}

export interface PdpFeaturesIconsResponse {
  _uid: string
  component: 'pdp-list-icons'
  icons: PdpFeatureIcon[]
  _editable: string
}

export interface PdpNormalizedFeaturesIcon {
  id: string
  key: string
  image: {
    id: string
    url: string
    alt: string
  }
}

export function normalizePdpFeaturesIcons(pdpFeaturesIconsStory: Storyblok.Story<PdpFeaturesIconsResponse>): PdpNormalizedFeaturesIcon[] {
  const { icons } = pdpFeaturesIconsStory.content
  return icons.map((icon) => {
    return {
      id: icon._uid,
      key: icon.title?.trim(),
      image: {
        id: icon.icon.id,
        url: icon.icon.filename?.trim(),
        alt: icon.icon.alt,
      },
    }
  })
}
