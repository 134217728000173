import type * as Helpers from 'types/helpers'
import type { Environment } from '~/types/environment'

export const normalizePrice = (price: number) => price / 100.0

export function parsePriceV1(priceRaw: number, skipDecimalZeros: boolean, currency: Environment.Currency, marketCountryCode: Environment.CountryCode): string {
  let price = priceRaw.toFixed(2)
  if (skipDecimalZeros && price.includes('.00'))
    price = price.slice(0, price.length - 3)

  switch (currency.code) {
    case 'GBP':
    case 'AUD':
    case 'JPY':
    case 'KRW':
      return `${currency.symbol}${price}`
    case 'CHF':
      return `${currency.symbol} ${price}`
    case 'EUR':
      if (marketCountryCode === 'NL' || marketCountryCode === 'AT')
        return `${currency.symbol} ${price}`
      if (marketCountryCode === 'IE' || marketCountryCode === 'LU')
        return `${currency.symbol}${price}`

      return `${price} ${currency.symbol}`
    default:
      return `${price} ${currency.symbol}`
  }
}

/**
 * NOTE: Not used/tested!
 *
 * Price parsing function to test the Intl.NumberFormat instead of manually parsing.
 *
 * Currently not working as we do not get the correct locale string (storefront.currentMarket.locale.html).
 * For Italian we get 'en-it', which will format the price using the english format instead of italian.
 * 'en-IT': €100   'it-IT': 100 €
 * https://en.wikipedia.org/wiki/Language_and_the_euro
 */
// export function parsePriceV2(price: number, skipDecimalZeros: boolean, currency: Environment.Currency, locale: string) {
//   const formatter = new Intl.NumberFormat(locale, {
//     style: 'currency',
//     minimumFractionDigits: skipDecimalZeros ? 0 : undefined,
//     currency: currency.code,
//     currencyDisplay: "narrowSymbol"
//   });
//   return formatter.format(price)
// }

export const price: Helpers.PriceHelper = (price, normalizeDecimals?, skipDecimalZeros = true) => {
  const { currency, currentMarketCountryCode } = useStorefrontStore()

  if (typeof price === 'string')
    price = Number.parseInt(price)
  if (typeof price === 'object')
    price = price.final

  if (normalizeDecimals)
    price = normalizePrice(price)

  return parsePriceV1(price, skipDecimalZeros, currency, currentMarketCountryCode)
}

/**
 * These are the rates and adjustments used in the warehouse system to price different products per market.
 * We use the pretty value to show a more user-friendly exchange rate in the UI. For example in the price range slider.
 */
interface ExchangeRates {
  [key: string]: {
    exchangeRate: number
    exchangeRatePretty?: number //
    currencyAdjustment: number
  }
}

const exchangeRates: ExchangeRates = {
  EUR: { exchangeRate: 1, currencyAdjustment: 0 }, // EUR is the base currency
  CNY: { exchangeRate: 7.23, exchangeRatePretty: 7, currencyAdjustment: 0 },
  SEK: { exchangeRate: 10, currencyAdjustment: 0 },
  GBP: { exchangeRate: 1, currencyAdjustment: 0 },
  DKK: { exchangeRate: 7.5, currencyAdjustment: 0 },
  CHF: { exchangeRate: 1.05, exchangeRatePretty: 1, currencyAdjustment: 5 },
  AUD: { exchangeRate: 1.6, exchangeRatePretty: 1.5, currencyAdjustment: 0 },
  NZD: { exchangeRate: 1.66, exchangeRatePretty: 1.5, currencyAdjustment: 10 },
  NOK: { exchangeRate: 10.23, exchangeRatePretty: 10, currencyAdjustment: 20 },
  CZK: { exchangeRate: 25.4, exchangeRatePretty: 25, currencyAdjustment: 0 },
  HKD: { exchangeRate: 8.84, exchangeRatePretty: 9, currencyAdjustment: 50 },
  JPY: { exchangeRate: 128.07, exchangeRatePretty: 130, currencyAdjustment: 5 },
  KRW: { exchangeRate: 1334.36, exchangeRatePretty: 1300, currencyAdjustment: 0 },

  USD: { exchangeRate: 1, currencyAdjustment: 0 }, // USD is priced independently
}

type ExchangePrice = (price: number, currency: string, options?: {
  pretty?: boolean
}) => number

export const exchangePrice: ExchangePrice = (price, currency, options) => {
  const { exchangeRate = 1, currencyAdjustment = 0, exchangeRatePretty } = exchangeRates[currency]
  const { pretty = false } = options || {}

  const rate = pretty && exchangeRatePretty ? exchangeRatePretty : exchangeRate
  const adjustedPrice = price * rate * (1 + currencyAdjustment / 100)

  return adjustedPrice
}
