<script setup lang="ts">
import { useElementSize } from '@vueuse/core'
import { useSpeechBubbleStore } from '~/stores/speechBubble'

interface Props {
  device: 'desktop' | 'mobile'
}
const props = defineProps<Props>()
const speechBubbleStore = useSpeechBubbleStore()
const speechBubbleColor = ref('default')
const route = useRoute()
const speechBubble = ref(null)
const { height } = useElementSize(speechBubble)

function setSpeechBubbleColor(variant: string) {
  switch (variant) {
    case 'default':
      return 'default'
    case 'menu-expanded':
      return 'menu-expanded'
    case 'highlight':
      return 'highlight'
    case 'transaction':
      return 'transaction'
    default:
      return 'default'
  }
}

watch(
  () => speechBubbleStore.variant,
  () => {
    speechBubbleColor.value = setSpeechBubbleColor(speechBubbleStore.variant)
  },
)
watch(
  () => route.fullPath,
  () => {
    if (route.params.sku || route.params.context === 'search')
      return

    speechBubbleStore.hideSpeechBubble()
  },
)

watch(
  () => [speechBubbleStore.message, speechBubbleStore.time],
  () => {
    if (speechBubbleStore.time > 0) {
      setTimeout(() => {
        speechBubbleStore.hideSpeechBubble()
      }, speechBubbleStore.time)
    }
  },
)

watch(
  () => height.value,
  () => {
    speechBubbleStore.setSpeechBubbleHeight(height.value)
  },
)

const isSpeechBubbleHidden = computed(() => {
  return speechBubbleStore.message === ''
})
</script>

<template>
  <p
    ref="speechBubble"
    class="speech-bubble" :class="[
      { desktop: props.device === 'desktop' },
      speechBubbleColor,
      { hidden: isSpeechBubbleHidden },
    ]"
    @click="speechBubbleStore.hideSpeechBubble"
  >
    {{ speechBubbleStore.message }}
  </p>
</template>

<style lang="scss" scoped>
.speech-bubble {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.4rem;
  position: absolute;
  top: 4.5rem;
  left: 1.3rem;
  transform: translateX(-50%);
  border-radius: 2.2rem;
  padding: 0.8rem 1.6rem;
  text-align: center;
  z-index: 4;
  min-width: 232px;
  &::before {
    mask: url('/icons/spike.svg') no-repeat center / contain;
    background-color: var(--bg-color);
    content: '';
    display: block;
    width: 5.9rem;
    height: 1.2rem;
    position: absolute;
    top: -1.1rem;
    transform: translateX(-50%);
    left: 50%;
  }
}
.desktop {
  white-space: nowrap;
  width: fit-content;
  left: -1rem;
  transform: unset;
  &::before {
    left: -0.65rem;
    transform: unset;
  }
}
.default {
  background-color: var(--pink-light);
  --bg-color: var(--pink-light);
}
.menu-expanded {
  background-color: var(--pink);
  --bg-color: var(--pink);
}
.highlight {
  background-color: var(--yellow);
  --bg-color: var(--yellow);
}
.transaction {
  background-color: var(--green);
  --bg-color: var(--green);
}
.hidden {
  display: none;
}
</style>
