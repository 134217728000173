<script setup lang="ts">
interface Props {
  titleKey?: string
  richtextMessage?: string
  messageKey?: string
  btnLabelKey?: string
  primaryColor?: string
  secondaryColor?: string
  context: 'menu' | 'newsletter-block' | 'popup'
}

withDefaults(defineProps<Props>(), {
  titleKey: '',
  richtextMessage: undefined,
  messageKey: '',
  btnLabelKey: 'Ok!',
  primaryColor: 'var(--black)',
  secondaryColor: 'var(--pink)',
})

const emit = defineEmits(['handleButton'])
</script>

<template>
  <span class="title" :class="[context]"> {{ $t(titleKey) }} </span>
  <div
    v-if="richtextMessage"
    class="sub-text"
    data-test="dialog-text"
    v-html="richtextMessage"
  />
  <p v-if="messageKey" class="sub-text" data-test="dialog-text">
    {{ $t(messageKey) }}
  </p>
  <ButtonBasic
    class="dialog-btn"
    tag="button"
    design="radius-without-border"
    :background-color="primaryColor"
    size="medium"
    data-test="dialog-btn"
    @click.stop="emit('handleButton')"
  >
    {{ $t(btnLabelKey) }}
  </ButtonBasic>
</template>

<style lang="scss" scoped>
@import 'assets/scss/typography/headings';
@import 'assets/scss/rules/breakpoints';
@import 'assets/scss/typography/body';

.title {
  color: v-bind(primaryColor);
  font-weight: bold;
}

.sub-text {
  @include body2;
  margin-top: 0.5rem;
  margin-bottom: 1.6rem;
}

.newsletter-block .title {
  @include h5;
  color: inherit;
  text-align: center;

  @media (min-width: $tablet) {
    @include h4;
    color: inherit;
  }
}

.newsletter-block .sub-text {
  @include body2;
  color: inherit;
  text-align: center;
  margin-top: 1.8rem;

  @media (min-width: $tablet) {
    @include body1;
    color: inherit;
  }
}

.popup .sub-text {
  text-align: center;
}

.dialog-btn {
  color: v-bind(secondaryColor);
  width: 100%;

  @media (min-width: $tablet) {
    max-width: 10rem;
  }
}

.popup .dialog-btn {
  width: 100%;

  @media (min-width: $tablet) {
    max-width: 100%;
  }
}
</style>
