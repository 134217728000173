import { defineStore } from 'pinia'

export const useSpeechBubbleStore = defineStore('speechBubble', {
  state: () => ({ variant: '', message: '', time: 0, height: 0 }),
  actions: {
    setSpeechBubble(variant: string, message: string | undefined, time = 0) {
      this.variant = variant
      this.message = message ?? ''
      this.time = time
    },
    hideSpeechBubble() {
      this.message = ''
      this.variant = ''
    },
    setSpeechBubbleHeight(height: number) {
      this.height = height
    },
    setSpeechBubbleSearchResults(count?: number) {
      const variant = 'highlight'
      const message = count
        ? this.getStates()?.resultsFound.replace('{results}', `${count}`)
        : this.getStates()?.searchResult

      this.setSpeechBubble(variant, message, 10000)
    },
    getStates() {
      const storyblokStore = useStoryblokStore()
      const { state } = storeToRefs(storyblokStore)
      const { speechBubbleStates } = state.value

      return speechBubbleStates
    },
  },
})
