<script setup lang="ts">
import type { Product } from 'types/models/product'

interface Props {
  values: Product[]
  activeIndex: number
}

withDefaults(defineProps<Props>(), {
  values: () => [],
  activeIndex: -1,
})

const emit = defineEmits(['hover', 'click'])
const isTabbing = ref<boolean>(false)

function setIsTabbing({ key }: KeyboardEvent) {
  isTabbing.value = ['Tab', 'Shift', 'Enter'].includes(key) ?? false
}

onMounted(() => {
  document.addEventListener('keydown', setIsTabbing)
})

onUnmounted(() => {
  document.removeEventListener('keydown', setIsTabbing)
})
</script>

<template>
  <ul class="suggestion-list" :class="[{ tabbing: isTabbing }]">
    <li :class="{ active: activeIndex === -1 }" tabindex="-1" class="suggestion">
      <button type="button" class="suggestion show-more" @click="() => emit('click')">
        {{ $t('showMore') }}
      </button>
    </li>
    <li
      v-for="(suggestion, index) in values"
      :key="`${suggestion.sku}-${index}`"
      class="suggestion"
      data-test="search-suggestion"
      tabindex="-1"
      :class="{ active: activeIndex === index }"
      @mouseover="emit('hover', Number(index))"
      @mouseleave="emit('hover', -1)"
    >
      <button type="button" class="suggestion" @click="emit('click', suggestion.id)">
        <ImageResponsive
          provider="fastly"
          :src="suggestion?.product?.photo ?? ''"
          alt=""
          :max-width="40"
          ratio="1:1"
        />
        <span>
          {{ suggestion?.product?.title ?? '' }}
        </span>
      </button>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';

ul {
  color: var(--black);
  position: absolute;
  bottom: 0.2rem;
  left: 0;
  list-style: none;
  background: var(--white);
  transform: translateY(calc(100% + 0.2rem));
  border: 1px solid var(--gray-light);
  z-index: 3;
  padding: 0;
  margin: 0;
  transition: all ease-in-out 0.2s;
  width: 100%;
  font-size: 1.6rem;
}

li:not(:last-of-type) {
  border-bottom: 1px solid var(--gray-light);
}

ul:not(.tabbing) li.active button {
  background: var(--green);
}

button {
  display: grid;
  grid-template-columns: 4rem 1fr;
  width: 100%;
  transition: background-color ease-in-out 0.2s;
  align-items: center;
  min-height: 4rem;
  padding: 0.2rem;
  gap: 1rem;
  text-align: left;

  &.show-more {
    text-align: unset;
    place-items: center;
    grid-template-columns: unset;
  }

  &:focus {
    background: var(--green);
  }
}

@media (min-width: $tablet) {
  ul {
    transform: translateY(calc(100% + 0.6rem));
  }
}
</style>
