export const useModalStore = defineStore('ModalStore', () => {
  const blockModals = ref(false)
  const showNewsletter = ref(false)
  const showFilterMenu = ref(false)
  const showCookieBanner = ref(false)
  const showCookieSettings = ref(false)
  const showCountrySwitcher = ref(false)
  const showColorSelectorList = ref(false)

  return {
    blockModals,
    showNewsletter,
    showFilterMenu,
    showCookieBanner,
    showCookieSettings,
    showCountrySwitcher,
    showColorSelectorList,
  }
})
