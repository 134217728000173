export const useRootStore = defineStore('RootStore', () => {
  const earlyBirdRecordIds = ref(new Set<string>())
  const lastEarlyBirdRecord = ref<string | undefined>(undefined)

  const addEarlyBirdRecord = (record?: string) => {
    if (!record)
      return console.warn('Missing record for ebRecoIds')
    earlyBirdRecordIds.value.add(record)
    lastEarlyBirdRecord.value = record
    setEarlyBirdRecordsToWindow()
  }

  function clearEarlyBirdRecords() {
    earlyBirdRecordIds.value = new Set()
  }

  function setEarlyBirdRecordsToWindow() {
    if (process.client)
      window.ebRecoIds = Array.from(earlyBirdRecordIds.value)
  }

  return {
    earlyBirdRecordIds,
    addEarlyBirdRecord,
    clearEarlyBirdRecords,
    lastEarlyBirdRecord,
    setEarlyBirdRecordsToWindow,
  }
})
