/**
 * A simple game loop that updates the game logic at a fixed rate, and draws the game at a variable rate.
 * @param fps How often to update the game logic
 * @param update What to do when updating the game logic
 * @param draw What to do when drawing the game
 * @param initialState The context that will be passed to the update and draw functions
 * @returns Start and stop functions to control the game loop
 */
export function setupGameLoop<GameLoopState>(
  fps: number,
  update: (ctx: GameLoopState) => void,
  draw: (ctx: GameLoopState) => void,
  initialState: GameLoopState,
) {
  const frameDuration = 1000 / fps
  let rafHandle: ReturnType<typeof requestAnimationFrame> | undefined
  let prevDrawTime: number | undefined
  let lag = 0
  let state = initialState

  function onAnimationFrame(curDrawTime: number) {
    if (!prevDrawTime)
      prevDrawTime = curDrawTime

    // Keep track of how much the draw loop is lagging behind
    lag += curDrawTime - prevDrawTime

    // Update the game logic x amount of times based on the FPS we've designed it for
    while (lag >= frameDuration) {
      update(state)
      lag -= frameDuration
    }

    draw(state)

    prevDrawTime = curDrawTime
    rafHandle = requestAnimationFrame(onAnimationFrame)
  }

  function startGameLoop() {
    prevDrawTime = undefined
    rafHandle = requestAnimationFrame(onAnimationFrame)
  }

  function stopGameLoop() {
    if (!rafHandle)
      return

    cancelAnimationFrame(rafHandle)
    rafHandle = undefined
  }

  return {
    startGameLoop,
    stopGameLoop,
    isRunning: () => rafHandle !== undefined,
    forceDraw: () => draw(state),
    forceUpdate: () => update(state),
    updateGameLoopState: (stateUpdate: Partial<GameLoopState>) => state = { ...state, ...(stateUpdate as GameLoopState) },
    resetGameLoopState: () => state = initialState,
  }
}
