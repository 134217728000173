import type { Product, ProductVariant } from 'types/models/product'
import type { Environment } from 'types/environment'

export function getDisplayVariant(product: Product): ProductVariant {
  let selected = product.variants[0] as ProductVariant

  if (!selected)
    throw new ReferenceError(`Missing variants for product: ${product.sku}`)

  for (const variant of product.variants) {
    const { discount, price } = variant

    if (price.final === 0)
      continue

    switch (true) {
      case selected.price.final === 0:
        selected = variant
        break
      case discount.absolute > selected!.discount.absolute:
        selected = variant
        break
      case discount.absolute <= selected!.discount.absolute && price.final < selected!.price.final:
        selected = variant
        break
    }
  }

  return selected
}

export function getProductPrice(currency: Environment.Currency, variant: ProductVariant) {
  if (variant.currencies && variant.currencies[currency.code]) {
    return {
      originalPrice: variant.currencies[currency.code].price,
      finalPrice: variant.currencies[currency.code].finalPrice,
    }
  }

  return {
    originalPrice: variant.price.original,
    finalPrice: variant.price.final,
  }
}
