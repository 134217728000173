import type * as Brink from './vendors/brink'
import type * as Contact from './models/contact.model'
import type { Order } from './models/order'

export const checkoutViews = ['cart', 'address', 'payment', 'success'] as const
export type CheckoutView = (typeof checkoutViews)[number]

export function isCheckoutView(view: any): view is CheckoutView {
  return view && checkoutViews.includes(view)
}

export function isCheckoutSuccessView(view: any): view is 'success' {
  return view === 'success'
}

export interface OrderInformation {
  order: Order
  session: Brink.AdyenSession
}

export interface SummaryData {
  itemsCount: number
  priceProducts: number
  priceShipping: number
  priceDiscount: number
  priceTotal: number
  externalTaxAmount?: number
}

interface CheckoutAddress {
  billing: Contact.Address
  shipping: Contact.Address
  useShippingForBilling: boolean
}

export interface CheckoutInformation {
  address: CheckoutAddress
  contact: Contact.Information
}
