export interface ConfigItem {
  id: string
  queryParam?: string
  translationKey?: string
  value: number | string | boolean | null | undefined
}
/**
 * @param key - the normalized name for the facet, since the facet ids in XO are not consistent
 * @param facetId - XO facet id
 * @param queryParam - the query param name for the facet
 * @param valuePrefix - XO facet value prefix, we dont want this inside the query param
 */
export interface ConfigGroup {
  key: string
  queryParam?: string
  apiId: string
  apiItemsPrefix?: string
  translationKey?: string
  type: 'facet' | 'filter'
  items?: ConfigItem[]
  inputType?: 'checkbox' | 'radio' | 'range' | 'color-checkbox'
}

export const COLOR_QUERY = 'color'
export const MATERIAL_QUERY = 'material'

/**
 * A Facet comes from the API.
 * A Filter is defined in the APP.
 */
export const FACET_CONFIG: ConfigGroup[] = [
  {
    key: 'categoryIds',
    apiId: 'facet-categoryIds',
    type: 'facet',
  },
  {
    key: 'categoryTag',
    apiId: 'facet-categoryTag',
    type: 'facet',
  },
  {
    key: 'price',
    inputType: 'range',
    apiId: 'lowestPrice',
    type: 'filter',
    items: [
      {
        id: 'price_from',
        value: 0,
      },
      {
        id: 'price_to',
        value: 300,
      },
    ],
  },
]
