import { z } from 'zod'
import type { StoryblokContent } from '~/types/storyblok'
import type { Story } from '~/types/vendors/storyblok'
import type Storyblok from '~/types/vendors/storyblok'

export interface SegmentContent extends StoryblokContent {
  id: Storyblok.Text
  utm_campaign: Storyblok.Text
  utm_content: Storyblok.Text
  component: 'segment'
  hide_regular_newsletter: Storyblok.Boolean
}

const Segment = z.object({
  uid: z.string(),
  id: z.string(),
  utmCampaigns: z.array(z.string()).default([]),
  utmContents: z.array(z.string()).default([]),
  hideNewsletter: z.boolean().default(true),
})

export type Segment = z.infer<typeof Segment>

export function normalizeSegment(segment: SegmentContent): Segment {
  const transformed: Segment = {
    uid: segment._uid,
    id: segment.id,
    utmCampaigns: segment.utm_campaign?.split(','),
    utmContents: segment.utm_content?.split(','),
    hideNewsletter: segment.hide_regular_newsletter,
  }

  try {
    return Segment.parse(transformed)
  }
  catch (error) {
    console.error(`Failed to normalize segment: ${error as string}`)
    return transformed
  }
}

export function normalizeSegmentStories(segments: Story<SegmentContent>[]): Segment[] {
  return segments.map(segment => normalizeSegment(segment.content))
}
