export default defineNuxtPlugin(() => {
  polyfillToSorted()
})

function polyfillToSorted() {
  if (!Array.prototype.toSorted) {
    // eslint-disable-next-line no-extend-native
    Array.prototype.toSorted = function (compareFunction) {
      return this.slice().sort(compareFunction)
    }
  }
}
