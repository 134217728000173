import type { Environment } from 'types/environment'

export function isInTimeLimit(time: string, limit: number): boolean {
  const timeInSec = (new Date().getTime() - new Date(time).getTime()) / 1000

  if (timeInSec < limit)
    return true
  else
    return false
}

export type dataByKey<Value, Key extends string | number | symbol> = {
  [key in Key]?: Value
}

export function getByCountryCode<Value>(code: Environment.CountryCode, name: string) {
  return getByKey<Value, Environment.CountryCode>(code, name)
}

export function getByWarehouseRegionCode<Value>(code: Environment.WarehouseRegions, name: string) {
  return getByKey<Value, Environment.WarehouseRegions>(code, name)
}

export function getByKey<Value, Key extends string | number | symbol>(storeCode: Key, name: string) {
  const unparsedTokens = localStorage.getItem(name)
  try {
    const tokens: dataByKey<Value, Key> = unparsedTokens ? JSON.parse(unparsedTokens) : {}

    return tokens[storeCode]
  }
  catch (e) {
    return undefined
  }
}

export function patchByCountryCode<Value>(code: Environment.CountryCode, name: string, value: any): Value {
  return patchByKey<Value, Environment.CountryCode>(code, name, value)
}

export function patchByWarehouseRegionCode<Value>(code: Environment.WarehouseRegions, name: string, value: any): Value {
  return patchByKey<Value, Environment.WarehouseRegions>(code, name, value)
}

export function patchByKey<Value, Key extends string | number | symbol>(code: Key, name: string, value: any): Value {
  const unparsedData = localStorage.getItem(name)
  let data

  if (unparsedData) {
    try {
      data = JSON.parse(unparsedData)
      data[code] = value
      localStorage.setItem(name, JSON.stringify(data))
    }
    catch (error) {
      console.error('JSON Data is corrupted. Resetting data.')
      data = { [code]: value }
      localStorage.setItem(name, JSON.stringify(data))
    }
  }
  else {
    data = { [code]: value }
    localStorage.setItem(name, JSON.stringify(data))
  }

  return data
}

export function setByKey<Value, Key extends string | number | symbol>(code: Key, name: string, value: any): Value {
  const data = { [code as Key]: value as Value }
  localStorage.setItem(name, JSON.stringify(data))

  return value
}
