import { getAllMarkets, getGeneratedStorefronts } from 'lib/storefronts'

import type { Environment } from 'types/environment'
import type { Storefront } from 'types/models/storefront'

export const useStorefrontStore = defineStore('StorefrontStore', () => {
  const all = ref(getGeneratedStorefronts())
  const current = ref<Storefront>(getGeneratedStorefronts()[0] as Storefront)
  const currentMarketCountryCode = ref<Environment.CountryCode>('DE')

  const allMarkets = computed(() => getAllMarkets())
  const currentStorefrontCode = computed(() => current.value.storefrontCode)
  const currentMarket = computed(
    () =>
      allMarkets.value.find(market => market.countryCode === currentMarketCountryCode.value)
      || current.value.markets[0]!,
  )
  const currency = computed(() => currentMarket.value.currency)
  const contentKey = computed(() => current.value.contentKey)
  const language = computed(() => currentMarket.value.language)

  const setCurrentMarket = (
    countryCode: Environment.CountryCode,
  ) => {
    currentMarketCountryCode.value = countryCode
  }

  const setCurrentStorefront = (storefront: Storefront) => {
    current.value = storefront
  }

  return {
    all,
    current,
    currentMarketCountryCode,
    allMarkets,
    currentStorefrontCode,
    currency,
    currentMarket,
    contentKey,
    language,

    setCurrentMarket,
    setCurrentStorefront,
  }
})
