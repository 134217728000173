<script lang="ts" setup>
const { $t } = useNuxtApp()

const links = ref<{ link: string, icon: string, name: string }[]>([
  {
    link: 'https://www.instagram.com/happysocks/',
    icon: '/icons/social-media/instagram.svg',
    name: 'Instagram',
  },
  {
    link: 'https://www.facebook.com/HappySocks',
    icon: '/icons/social-media/facebook.svg',
    name: 'Facebook',
  },
  {
    link: 'https://www.youtube.com/c/happysocks',
    icon: '/icons/social-media/youtube.svg',
    name: 'Youtube',
  },
  {
    link: 'https://www.tiktok.com/@happysocks',
    icon: '/icons/social-media/tik-tok.svg',
    name: 'TikTok',
  },
])
</script>

<template>
  <div class="social-media">
    <p class="social-media__title">
      {{ $t('followHappySocks') }}
    </p>
    <ul class="social-media__links">
      <li v-for="(link, index) in links" :key="index" class="social-media__link">
        <NuxtLink :to="link.link" target="_blank">
          <img :src="link.icon" :alt="link.name" width="24" height="24" loading="lazy">
        </NuxtLink>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/scss/typography/body';
@import 'assets/scss/rules/breakpoints';
.social-media {
  &__title {
    font-size: 1.2rem;
    color: var(--text-secondary);
    font-weight: lighter;

    @media (min-width: $large-breakpoint) {
      font-size: 1.1rem;
    }
  }

  &__links {
    display: flex;
  }

  &__link {
    a {
      display:inline-block;
      padding: 1.2rem 1.2rem;
    }
    img {
      max-width: unset;
    }

    &:nth-of-type(1) {
      margin-left: 0.3rem;
    }
  }
}

.footer {
  & .social-media__title {
    margin-left: 0;
    margin-bottom: 0;
  }
  & .social-media__links {
    margin-left: -1.2rem;
  }
  & .social-media__link {
    margin-left: 0;
  }
}

.navbar {
  & .social-media__links {
    margin-left: -1.6rem;
    margin-bottom: -1.2rem;
  }
}
</style>
