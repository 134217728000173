import StoryblokClient from 'storyblok-js-client'
import type { $Fetch } from 'nitropack'

import { getCategoriesDefault } from '../shared-content'

import type { Storefront } from '../../../types/models/storefront'
import type { Datasource, StoryblokRequestParameters } from '../../../types/storyblok'
import type { SharedContentResponse } from '../../../types/storyblok/shared-content'
import type { Category } from '../../../utils/categories/normalizeCategories'
import { fetchCategories } from '../fetchCategories'

interface Options {
  version: 'draft' | 'published'
  token: string
}

// 1. fetch all shared content from storyblok
export async function fetchSharedContent(api: $Fetch, storefront: Storefront, constants: Datasource[], options?: Options): Promise<{ stories: SharedContentResponse['stories'], categories: Category[] }> {
  let storiesByMarket = constants
    .find(({ name }) => name === 'STORIES_BY_MARKET')
    ?.value.split(',')
    .map(slug => `${storefront.storefrontCode}/${slug}`)
    .join(',')

  if (storiesByMarket)
    storiesByMarket = `${storiesByMarket},`

  const by_slugs = encodeURI(
    `${storiesByMarket}${storefront.contentKey}/shared-content/*,_default/shared-content/*`,
  )

  const language = storefront.contentLocale

  const storiesPerPage = 100
  const params: StoryblokRequestParameters = {
    per_page: storiesPerPage,
    by_slugs,
    page: 1,
  }
  if (language !== 'default')
    params.language = language

  try {
    const data = await api.raw<SharedContentResponse>(`/stories`, {
      params,
    })
    const stories = data._data?.stories
    let page = 1
    let storiesLength = stories?.length || 0

    while (storiesLength === storiesPerPage) {
      page++
      params.page = page
      const pageData = await api.raw<SharedContentResponse>(`/stories`, {
        params,
      })
      storiesLength = pageData._data?.stories?.length || 0
      if (pageData._data?.stories) {
        stories?.push(...pageData._data.stories)
      }
    }

    const url = data.url
    const headers = data.headers

    let categories: Category[] = []
    try {
      const token = options?.token

      if (!token)
        throw new Error('Token is missing')

      const version = options?.version || 'published'

      const client = new StoryblokClient({
        accessToken: token,
      })
      categories = await fetchCategories({ version, token, locale: language, client })
    }
    catch (e) {
      console.error(e)

      categories = await getCategoriesDefault(storefront.contentLocale)
    }

    if (!stories || !stories.length) {
      return Promise.reject(
        new EvalError(
          `No shared content, stories. Headers: ${JSON.stringify(
            headers,
          )}, Url: ${url}, Params: ${JSON.stringify(params)}`,
        ),
      )
    }

    if (!categories || !categories.length) {
      return Promise.reject(
        new EvalError(
          `No categories. Headers: ${JSON.stringify(
            headers,
          )}, Url: ${url}, Params: ${JSON.stringify(params)}`,
        ),
      )
    }

    return {
      stories,
      categories,
    }
  }
  catch (e) {
    return Promise.reject(e)
  }
}
