export default defineNuxtPlugin({
  name: 'audioeye',
  parallel: true,
  dependsOn: ['storefront'],
  setup() {
    const { $currentStorefront } = useNuxtApp()

    const { storefrontCode } = $currentStorefront
    if (storefrontCode === 'us') {
      const script = document.createElement('script')
      script.text
      = '!function(){var b=function(){window.__AudioEyeSiteHash = "a3737704745755fe89a7f7d4bb8d9880"; var a=document.createElement("script");a.src="https://wsmcdn.audioeye.com/aem.js";a.type="text/javascript";a.setAttribute("async","");document.getElementsByTagName("body")[0].appendChild(a)};"complete"!==document.readyState?window.addEventListener?window.addEventListener("load",b):window.attachEvent&&window.attachEvent("onload",b):b()}();'
      document.body.appendChild(script)
    }
  },
})
