import type { Environment } from 'types/environment'
import { price } from './price'
import type { QueryObject } from './routing'
import { extractPageFromRoute } from './routing'
import { toKebabCase } from './strings'
import type { LocationQuery } from '#vue-router'
import { type FilterGroup, type FilterItem, convertConfigGroupToFilterGroup, convertConfigItemToFilterItem, normalizeFilterKeys } from '~/types/models/filter.model'
import type { ConfigGroup } from '~/data/facets'
import { queryValueToFilterValue } from '~/types/xo/facet'

export function getFilterConfigGroupByQuery(queryKey: string): ConfigGroup | undefined {
  const facetStore = useFacetStore()
  return facetStore.DYNAMIC_FACET_CONFIG.find(facet => (facet.queryParam || facet.key) === queryKey)
}

export function getFilterItemTranslationKeyByQuery(queryKey: string, queryValue: string): string {
  const configGroup = getFilterConfigGroupByQuery(queryKey)
  const valuePrefix = configGroup?.apiItemsPrefix ?? ''
  const value = queryValueToFilterValue(queryValue, valuePrefix)

  return value
}

export function convertQueryToFilterGroups(query: QueryObject | LocationQuery): FilterGroup[] {
  const groups: FilterGroup[] = []

  Object.keys(query).forEach((queryParam) => {
    const configGroup = getFilterConfigGroupByQuery(queryParam)
    if (!configGroup)
      return

    const queryString = query[queryParam]
    if (typeof queryString !== 'string')
      return

    const valuePrefix = configGroup.apiItemsPrefix ?? ''
    const values = queryString.split(',')

    const selectedFilterGroup: FilterGroup = {
      ...convertConfigGroupToFilterGroup(configGroup),
      items: values.map((queryItem) => {
        const [queryItemParam, selectedValue] = queryItem.split(':')
        const configItem = configGroup.items?.find(i => i.id === queryItemParam)
        const id = valuePrefix ? queryValueToFilterValue(queryItemParam, valuePrefix) : queryItemParam

        const item: FilterItem = {
          ...(configItem ? convertConfigItemToFilterItem(configItem) : {}),
          id,
          disabled: false,
          value: selectedValue || true,
        }

        return item
      }),
    }

    groups.push(selectedFilterGroup)
  })

  return groups
}

export function getQueryKeyFromConfigGroup(group: ConfigGroup) {
  return group.queryParam ?? toKebabCase(group.key)
}

export function getQueryString(facet: FilterGroup) {
  function getItemQueryString(item: FilterItem) {
    const query = item.metadata?.queryParam ?? toKebabCase(item.id)

    if (item.value && typeof item.value !== 'boolean')
      return `${query}:${item.value}`

    return query
  }

  const values = facet.items.filter(item => item.value && item.value !== item.metadata?.defaultValue).map(getItemQueryString)

  return values.join(',')
}

export function parseParam(query: string | (string | null)[]) {
  const param = typeof query === 'string' ? decodeURIComponent(query) : ''
  return param.replace(/(\+|-)/g, ' ')
}

export function getPriceForPriceGroup(value: string, marketCountryCode: Environment.CountryCode) {
  const marketPriceFallback = 10
  const marketPriceMapping: Partial<Record<Environment.CountryCode, number>> = {
    SE: 100,
    NO: 100,
    DK: 90,
    CZ: 279,
    KR: 13000,
    AU: 19.95,
    CH: 13.95,
    JP: 1300,
  }

  if (value !== 'product-price-group-0' && value !== 'product-price-group-1')
    return undefined

  const marketPrice = marketPriceMapping[marketCountryCode] || marketPriceFallback
  return price(marketPrice)
}

export function isFilterClickAway(event: MouseEvent): boolean {
  const target = event?.target

  if (
    target instanceof HTMLElement
    && target.attributes.getNamedItem('data-filter-button-ignored-el')
  )
    return false

  return true
}

export function updateRouteQuery(groups: FilterGroup[]) {
  const route = useRoute()
  const router = useRouter()
  const query = { ...route.query }
  normalizeFilterKeys().forEach((key) => {
    const group = groups.find(g => g.id === key)

    if (!group) {
      const facetStore = useFacetStore()
      const configGroup = facetStore.DYNAMIC_FACET_CONFIG.find(facet => facet.key === key)
      delete query[configGroup ? getQueryKeyFromConfigGroup(configGroup) : toKebabCase(key)]
      return
    }

    const queryString = getQueryString(group)
    const queryId = group.metadata?.queryParam || group.id
    query[queryId] = queryString
  })

  const { pathWithoutPage } = extractPageFromRoute(route)

  if (JSON.stringify(query) !== JSON.stringify(route.query))
    window.scrollTo({ top: 0, behavior: 'instant' })

  return router.replace({ path: pathWithoutPage, query })
}
