/* eslint-disable ts/no-this-alias */
export function debounce(func: any, wait: number, immediate?: boolean) {
  let timeout: any
  return function (...args: any[]) {
    // @ts-expect-error this
    const context = this
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      timeout = null
      if (!immediate)
        func.apply(context, args)
    }, wait)
    if (immediate && !timeout)
      func.apply(context, args)
  }
}

if (import.meta.vitest) {
  const { it, expect, describe, vi } = import.meta.vitest
  describe('debounce', () => {
    it('should debounce', async () => {
      const fn = vi.fn()
      const debounced = debounce(fn, 100)
      debounced()
      debounced()
      debounced()
      await new Promise(resolve => setTimeout(resolve, 200))
      expect(fn).toBeCalledTimes(1)
    })
  })
}
