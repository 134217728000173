/**
 * useMediaQuery that does not cause hydration issues due to SSR
 */
export function useMediaQuerySSR(query: string, defaultValue?: boolean) {
  const _mq = useMediaQuery(query)
  const mq = ref<boolean>(!!defaultValue)

  onMounted(() => update())
  watch(_mq, () => update())

  function update() {
    mq.value = _mq.value
  }

  return mq
}
