export default function useScrollDirection({ topOffset = 0, minScroll = 0 }: { topOffset?: number, minScroll?: number } = {}) {
  const scrollDirection = ref<'down' | 'up' | 'unset'>('unset')
  const scrollTop = ref(0)
  let lastScrollTop: number = 0

  useEventListener('scroll', () => {
    scrollTop.value = document.documentElement.scrollTop

    // Scroll top amount before checking scroll direction
    if (scrollTop.value < topOffset)
      return

    // Scroll ofset before checking scroll direction
    if (Math.abs(scrollTop.value - lastScrollTop) < minScroll)
      return

    scrollDirection.value = scrollTop.value > lastScrollTop ? 'down' : 'up'
    lastScrollTop = scrollTop.value ?? 0
  }, { passive: false })

  return { scrollDirection, scrollTop }
}
