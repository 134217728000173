import { z } from 'zod'

export const tenantKeys = z.enum(['DE', 'EU', 'GL', 'NL', 'UK', 'US', 'default'])
export type TenantKey = z.infer<typeof tenantKeys>

const tenantWidgets = z.enum([
  'XO_TENANT_ID', // Search API Key
  'XO_TENANT_PLP_WIDGET',
  'XO_TENANT_PDP_WIDGET',
  'XO_TENANT_RANDOM_WIDGET',
  'XO_TENANT_SITEMAP_WIDGET',
  'XO_TENANT_SEARCH_CONFIG_ID',
  'BESTSELLERS_WIDGET',
  'LANDING_PAGE_WIDGET',
  'BASKET_WIDGET',
])

export type TenantWidgets = z.infer<typeof tenantWidgets>
const XOEnvironment = z.enum(['staging', 'production'])

export const tenantIdsType = z.record(XOEnvironment, z.record(tenantWidgets, z.record(tenantKeys, z.string())))
export type TenantIdsType = z.infer<typeof tenantIdsType>

export const tenantIds: TenantIdsType = {
  staging: {
    [tenantWidgets.enum.XO_TENANT_ID]: {
      default: '618e681bb57fadc0e06b2924',
      UK: '618e685c058d6e79b068712f',
      DE: '618e6815058d6e79b0687115',
      US: '618e681eb57fadc0e06b2932',
    },
    [tenantWidgets.enum.XO_TENANT_PLP_WIDGET]: {
      default: '6193ac8dcf880a13a6c0c1ab',
      DE: '6193aea1b57ffe4e47f54800',
      US: '6193b5bdcf880a13a6c0c415',
    },
    [tenantWidgets.enum.XO_TENANT_PDP_WIDGET]: {
      default: '6193acaa7e758630d5f37c76',
      DE: '6193ae92cf880a13a6c0c1bf',
      US: '6193b5ad0d1fa0ed6b6f6875',
    },
    [tenantWidgets.enum.XO_TENANT_RANDOM_WIDGET]: {
      default: '6193ac667e758630d5f37c72',
      DE: '6193aeb37e758630d5f37dba',
      US: '6193b5d0b57ffe4e47f54ab5',
    },
    [tenantWidgets.enum.XO_TENANT_SITEMAP_WIDGET]: {
      default: '6193ac58016c877a874698de',
      DE: '6193aebc016c877a874698fc',
      US: '6193b5dfcf880a13a6c0c42b',
    },
    [tenantWidgets.enum.XO_TENANT_SEARCH_CONFIG_ID]: {
      default: 'bf2e02a3-25af-4973-b9ae-b5048868dc51',
      DE: 'ab5a3c29-7067-43e7-bc69-d21b8fed037e',
      US: 'fd4aa697-1159-453f-aaaa-3910c1129ffd',
    },
    [tenantWidgets.enum.BESTSELLERS_WIDGET]: {
      default: '653a15773c9e426af6bb4c18',
    },
    [tenantWidgets.enum.LANDING_PAGE_WIDGET]: {
      default: '6537aefa3c9e426af6ba03c2',
      DE: '6537afbb5a219c0a35ea02fa',
      US: '6537af7e2d813f67db7afa31',
    },
    [tenantWidgets.enum.BASKET_WIDGET]: {
      default: '669f9b5146456891d972bc70',
    },
  },
  production: {
    [tenantWidgets.enum.XO_TENANT_ID]: {
      default: '5f749e405f73020dc65ae669',
      UK: '5f749e405f73020dc65ae669',
      DE: '5f885035efa75bf341ecee7e',
      EU: '5f885080933de0798b53deb1',
      GL: '5f884f1cefa75bf341ecee58',
      NL: '5f884f57e45c521ac77a7998',
      US: '5f884f88933de0798b53dea1',
    },
    [tenantWidgets.enum.XO_TENANT_PLP_WIDGET]: {
      default: '60151160644e5d0930341c21',
      DE: '6034e04a6186d965e4493bbc',
      EU: '6034df5a48b6b424866864c8',
      GL: '6034d6da48b6b4248668636b',
      NL: '603504546186d965e4493ccc',
      UK: '60151160644e5d0930341c21',
      US: '6034d54c48b6b42486686331',
    },
    [tenantWidgets.enum.XO_TENANT_PDP_WIDGET]: {
      default: '5f90250de3ae997da3763226',
      DE: '6034e03748b6b424866864ca',
      EU: '6034df485dfe653951806f18',
      GL: '6034d6c948b6b42486686353',
      NL: '603504425dfe653951806f55',
      UK: '5f90250de3ae997da3763226',
      US: '6034d5336186d965e4493aae',
    },
    [tenantWidgets.enum.XO_TENANT_RANDOM_WIDGET]: {
      default: '60195c3cbfa82b7e45203239',
      DE: '6034e0505dfe653951806f26',
      EU: '6034df625dfe653951806f1a',
      GL: '6034d6e0b2f537c11ed86660',
      NL: '6035045d3db79ed8c784be80',
      UK: '60195c3cbfa82b7e45203239',
      US: '6034d5555dfe653951806de5',
    },
    [tenantWidgets.enum.XO_TENANT_SITEMAP_WIDGET]: {
      default: '60cb3243f39e98f49b657738',
      UK: '60cb3243f39e98f49b657738',
      DE: '60dc19a2b613e14d9154fce6',
      EU: '60dc1a1239419b182f882ac4',
      GL: '60dc1a9c94ec381651274ce7',
      NL: '60dc1b4a39419b182f882ae5',
      US: '60dc1baeb613e14d9154fd64',
    },
    [tenantWidgets.enum.XO_TENANT_SEARCH_CONFIG_ID]: {
      default: '22217619-65d1-4d49-ba5f-4430bc128056',
      UK: '22217619-65d1-4d49-ba5f-4430bc128056',
      DE: '41f230c0-e62e-45b7-8222-82e98343fd07',
      EU: '9f04d7d6-accd-44f9-9789-b13e81e64b8c',
      GL: 'df829bda-f8df-44ea-ab36-626716b59149',
      NL: 'a9376f68-a571-479c-8398-0f185aec420e',
      US: '4b4497a2-42f8-4cf2-a281-e1e55d3392e7',
    },
    [tenantWidgets.enum.BESTSELLERS_WIDGET]: {
      default: '651ec47bdd88dff87bc3a53d',
      UK: '651ec47bdd88dff87bc3a53d',
      DE: '6539294fd966996614565330',
      EU: '653929db0471c5ce372dcc23',
      GL: '653929b9d966996614565332',
      NL: '6539298a3c9e426af6bb1db8',
      US: '653928c0d966996614565323',
    },
    [tenantWidgets.enum.LANDING_PAGE_WIDGET]: {
      default: '653106338bb42eece475e443',
      DE: '6537ae683c9e426af6ba03c0',
      US: '6537ab531c5819ad6694d8f1',
    },
    [tenantWidgets.enum.BASKET_WIDGET]: {
      default: '669fbdc1cdc810bbb2745690',
    },
  },
}
