const colors: { [key: string]: string } = {
  'beige': '#f1e2ca',
  'black': '#000',
  'blue': '#033ba7',
  'brown': '#846452',
  'burgundy': '#6f233e',
  'gray': '#a6a6a6',
  'green': '#009e48',
  'light blue': '#69bbe1',
  'multicolor':
    'linear-gradient(90deg, rgba(255,221,0,1) 20%, rgba(0,158,72,1) 20%, rgba(0,158,72,1) 40%, rgba(255,148,189,1) 40%, rgba(255,148,189,1) 60%, rgba(255,102,64,1) 60%, rgba(255,102,64,1) 80%, rgba(255,51,0,1) 80%, rgba(255,51,0,1) 100%)',
  'navy blue': '#1a1461',
  'orange': '#ff6640',
  'pink': '#ff94bd',
  'purple': '#af6bff',
  'red': '#ff3300',
  'turquoise': '#a9ffea',
  'white': '#fff',
  'yellow': '#ffdd00',
}

export function getDiscountColor(discount: number): string | undefined {
  const colors = {
    100: '#FF6699',
    80: '#FF8CB2',
    70: '#37A5D7',
    60: '#69BBE1',
    50: '#009933',
    40: '#6EC58B',
    30: '#FFDD00',
  }
  const color = Object.entries(colors).find(breakpoint => discount < Number.parseInt(breakpoint[0]))
  return color ? color[1] : undefined
}

export function getBackgroundByColorName(name: string): string {
  name = name.toLowerCase()

  return colors[name] || '#fff'
}

export function hexToHSL(hex: string) {
  if ((hex.length !== 4 && hex.length !== 7) || !hex.startsWith('#')) {
    console.error(`${hex} is not a valid hex code`)
    return { h: 0, s: 0, l: 100, formatted: 'hsl(0,0%,100%)' }
  }

  // Convert hex to RGB first
  let r: any = 0
  let g: any = 0
  let b: any = 0

  if (hex.length === 4) {
    r = `0x${hex[1]}${hex[1]}`
    g = `0x${hex[2]}${hex[2]}`
    b = `0x${hex[3]}${hex[3]}`
  }
  else if (hex.length === 7) {
    r = `0x${hex[1]}${hex[2]}`
    g = `0x${hex[3]}${hex[4]}`
    b = `0x${hex[5]}${hex[6]}`
  }

  // Then to HSL
  r /= 255
  g /= 255
  b /= 255

  const cmin = Math.min(r, g, b)
  const cmax = Math.max(r, g, b)
  const delta = cmax - cmin

  let h = 0
  let s = 0
  let l = 0

  if (delta === 0)
    h = 0
  else if (cmax === r)
    h = ((g - b) / delta) % 6
  else if (cmax === g)
    h = (b - r) / delta + 2
  else h = (r - g) / delta + 4

  h = Math.round(h * 60)

  if (h < 0)
    h += 360

  l = (cmax + cmin) / 2
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))
  s = +(s * 100).toFixed(1)
  l = +(l * 100).toFixed(1)

  return {
    h,
    s,
    l,
    formatted: `hsl(${h},${s}%,${l}%)`,
  }
}

const rainbow = {
  red: '\x1B[31m',
  green: '\x1B[32m',
  yellow: '\x1B[33m',
  blue: '\x1B[34m',
  magenta: '\x1B[35m',
  cyan: '\x1B[36m',
}

export const terminalColors = {
  reset: '\x1B[0m',
  bright: '\x1B[1m',
  dim: '\x1B[2m',
  underscore: '\x1B[4m',
  blink: '\x1B[5m',
  reverse: '\x1B[7m',
  hidden: '\x1B[8m',
  rainbow,
  fg: {
    ...rainbow,
    black: '\x1B[30m',
    white: '\x1B[37m',
  },
  bg: {
    black: '\x1B[40m',
    red: '\x1B[41m',
    green: '\x1B[42m',
    yellow: '\x1B[43m',
    blue: '\x1B[44m',
    magenta: '\x1B[45m',
    cyan: '\x1B[46m',
    white: '\x1B[47m',
    scarlet: '\x1B[48m',
  },
}
