export function useHeaderNavbarSubmenus() {
  const storyblokStore = useStoryblokStore()
  const storefrontStore = useStorefrontStore()
  const { currentStorefrontCode } = storeToRefs(storefrontStore)

  const submenus = computed(() => {
    const alternateMenuSplitTestCookie = useSplitTest('NAV_01_US_ALL')
    const isAlternateMenu = alternateMenuSplitTestCookie.value === 'variant1' && currentStorefrontCode.value === 'us'
    return isAlternateMenu ? storyblokStore.state.submenusAlternate : storyblokStore.state.submenus
  })

  return {
    submenus,
  }
}
