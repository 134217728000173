import type { Story } from 'types/storyblok'
import type { Environment } from 'types/environment'
import { getSortedStories } from './getSortedStories'

export function getMainStory(
  stories: Story[],
  baseContentKey: Environment.ContentKey,
  storefrontCode: Environment.StorefrontCode,
): Story<any> | undefined {
  return getSortedStories(stories, baseContentKey, storefrontCode).shift()
}
