export default defineNuxtPlugin(async () => {
  const cartStore = useCartStore()
  const initStore = useInitStore()
  const storefrontStore = useStorefrontStore()
  const orderStore = useOrderStore()
  const checkoutStore = useCheckoutStore()
  const mediaStore = useMediaStore()
  const route = useRoute()
  initStore.loading = true

  useCountryTabListener()

  cartStore.setCartTokenFromLocalStorage()
  orderStore.setSessionFromLocalStorage()
  checkoutStore.updateCountryCode()

  // Set UTM states
  useSegment().loadSegment()

  // Don't fetch cart when redirecting in payment step
  const isFetchCartRoute
    = route.name !== 'storyblok-plugin'
    && !route.query.redirectResult
    && Boolean(cartStore.cartToken)
    && !route.query.items

  try {
    const results = await Promise.allSettled([
      isFetchCartRoute && cartStore.fetchCart(),
      mediaStore.watchMedia(),
    ])
    if (results[0].status === 'fulfilled') {
      const cart = results[0].value
      if (cart && cart.countryCode !== storefrontStore.currentMarketCountryCode)
        cartStore.updateCountryCode(storefrontStore.currentMarketCountryCode)
    }

    // log any errors
    results.forEach((result) => {
      if (result.status === 'rejected')
        console.error('nuxtClientInit:', result.reason)
    })
  }
  catch (error) {
    console.error('nuxtClientInit:', error)
  }
  finally {
    initStore.loading = false
  }
})
