import type Storyblok from '~/types/vendors/storyblok'

export interface SpeechBubbleStates {
  addToCart: string
  menuExpanded: string
  resultsFound: string
  searchResult: string
  searchFocused: string
  useSpeechBuble?: boolean
}

export interface SpeechBubbleResponse extends Storyblok.Story {
  component: 'speechBubble'
  states: SpeechBubbleStates
}

export function normalizeSpeechBubble(
  speechBubble: Storyblok.Story<SpeechBubbleResponse>[] | undefined,
): SpeechBubbleStates {
  try {
    const { states } = speechBubble?.[0]?.content ?? {}

    if (!speechBubble || !states || !states.useSpeechBuble) {
      return {
        addToCart: '',
        menuExpanded: '',
        resultsFound: '',
        searchFocused: '',
        searchResult: '',
      }
    }
    else { return states }
  }
  catch (error) {
    console.error(error)
    throw new Error('Error while normalizing speech bubble')
  }
}
