import consola from 'consola'

type LogLevel = 'light' | 'verbose'
type LogTypes = 'api' | 'render' | 'cache'

export function logError(message: string, error?: any) {
  const { logLevel } = useRuntimeConfig().public

  if (logLevel === 'light')
    console.error(message)
  else if (logLevel === 'verbose')
    console.error(message, error || '')
}

export function logMessage(message: string, { level, type }: { level: LogLevel, type?: LogTypes } = { level: 'verbose', type: undefined }) {
  const { logLevel, logTypes } = useRuntimeConfig().public

  if (logLevel === level && (!type || logTypes.includes('all') || logTypes.includes(type)))
    consola.info(message)
}

export function trackTime(startTime = 0) {
  const currentTime = performance.now()
  return currentTime - startTime
}
