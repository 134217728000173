import { CART_TOKENS } from 'configuration/global.configuration'
import consola from 'consola'
import { TWO_DAYS_IN_SECONDS } from 'data/time/milliseconds'

import { getItemsCountWithQuantity } from 'lib/cart'
import {
  getByWarehouseRegionCode,
  isInTimeLimit,
  patchByWarehouseRegionCode,
} from 'lib/localStorage'

import type { Cart, CartToken, ShippingMethodName } from 'types/cart'
import type { Environment } from 'types/environment'
import type { AddProduct } from '~/contracts/CartRepositoryContract'

export type CartState = Omit<Cart, 'discountCode' | 'shippingMethod'>

const DEFAULT_STATE = {
  items: [],
  priceTax: 0,
  priceTotal: 0,
  priceShipping: 0,
  priceProducts: 0,
  priceDiscount: 0,
  itemsCount: 0,
  messages: [],
  countryCode: undefined,
  isFreeShippingDiscountCodeApplied: false,
}

export const useCartStore = defineStore('CartStore', () => {
  const state = ref<CartState>(DEFAULT_STATE)

  const loading = ref(false)
  const cartToken = ref<string | undefined>(undefined)

  const shippingStore = useShippingStore()
  const storefrontStore = useStorefrontStore()
  const gtm = useGTM()
  const discountCodeStore = useDiscountCodeStore()
  const checkoutStore = useCheckoutStore()
  // TODO: Check why $cart cannot be deconstructed
  const context = useNuxtApp()

  const isCartEmpty = computed(() => state.value.itemsCount === 0)
  const freeShipping = computed(
    () => state.value.isFreeShippingDiscountCodeApplied || shippingStore.selected?.price === 0,
  )

  const cartSummary = computed(() => ({
    priceTotal: state.value.priceTotal,
    priceProducts: state.value.priceProducts,
    priceShipping: state.value.priceShipping,
    priceDiscount: state.value.priceDiscount,
    itemsCount: getItemsCountWithQuantity(state.value.items),
  }))

  const removeCartToken = () => {
    const storefrontStore = useStorefrontStore()
    patchByWarehouseRegionCode(storefrontStore.current.warehouse.region, CART_TOKENS, undefined)
    cartToken.value = undefined
  }

  const reset = () => {
    removeCartToken()
    state.value = DEFAULT_STATE
    loading.value = false
  }

  const setCartToken = (jwtToken: string) => {
    patchByWarehouseRegionCode(storefrontStore.current.warehouse.region, CART_TOKENS, {
      jwt: jwtToken,
      date: new Date().toDateString(),
    })

    cartToken.value = jwtToken
  }

  const setCartTokenFromLocalStorage = () => {
    try {
      const currentToken = getByWarehouseRegionCode<CartToken>(
        storefrontStore.current.warehouse.region,
        CART_TOKENS,
      )

      if (!currentToken)
        throw String('Missing cart-token')

      if (isInTimeLimit(currentToken.date, TWO_DAYS_IN_SECONDS)) {
        cartToken.value = currentToken.jwt
      }
      else {
        removeCartToken()
        throw String('Old cart-token')
      }
    }
    catch (error) {
      consola.info(error)
    }
  }

  const updateCart = (cart: Cart) => {
    discountCodeStore.code = cart.discountCode
    shippingStore.selected = cart.shippingMethod

    checkoutStore.removeAddressView()

    state.value = {
      ...state.value,
      ...cart,
    }
  }

  const fetchCart = async () => {
    try {
      loading.value = true
      const cart = await context.$cart.fetchCart()

      updateCart(cart)

      return cart
    }
    catch (e: any) {
      if (e.message === 'RESET')
        removeCartToken()

      console.error('Failed to fetch cart', e)
      return Promise.reject(e)
    }
    finally {
      loading.value = false
    }
  }

  const addItem = async (id: string, quantity?: number) => {
    try {
      const cart = await context.$cart.addProduct({
        id,
        quantity: quantity || 1,
      })

      updateCart(cart)
      gtm.pushCartUpdate()
      return Promise.resolve()
    }
    catch (e) {
      return Promise.reject(e)
    }
    finally {
      loading.value = false
    }
  }

  const addItems = async (products: AddProduct[]) => {
    try {
      const cart = await context.$cart.addProducts(products)

      updateCart(cart)
      gtm.pushCartUpdate()
      return Promise.resolve()
    }
    catch (e) {
      return Promise.reject(e)
    }
    finally {
      loading.value = false
    }
  }

  const removeItem = async (barcode: string) => {
    try {
      const cart = await context.$cart.removeProduct(barcode)
      updateCart(cart)
      gtm.pushCartUpdate()
    }
    catch (e) {
      return Promise.reject(e)
    }
    finally {
      loading.value = false
    }
  }

  const updateItemQty = async (product: AddProduct) => {
    try {
      const cart = await context.$cart.updateProduct(product)

      updateCart(cart)
      gtm.pushCartUpdate()
    }
    catch (e) {
      console.error('Failed to remove item', product.id, e)
      return Promise.reject(e)
    }
  }

  const addShippingMethodToCart = async (id: ShippingMethodName) => {
    try {
      shippingStore.loading = true
      const cart = await context.$cart.selectShippingMethod(id)
      shippingStore.loading = false

      updateCart(cart)
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  const updateCountryCode = async (code?: Environment.CountryCode) => {
    if (!cartToken.value)
      return

    loading.value = true
    shippingStore.selected = undefined

    let countryObject

    if (code) {
      const market = storefrontStore.allMarkets.find(market => market.countryCode === code)
      if (market)
        countryObject = { countryCode: code, languageCode: market.language }
    }

    try {
      const cart = await context.$cart.updateCountryCode(countryObject)
      cart && updateCart(cart)
    }
    catch (error) {
    }
    finally {
      loading.value = false
    }
  }

  // Query in the form of 7333102442632x1,7333102606874x2,7333102608397x3
  // TODO: Add individual checks for every product
  async function restoreCartFromQuery(query: string) {
    const items = query.split(',').reduce((acc, item) => {
      const [id, quantity] = item.split('x')
      if (id && quantity) {
        acc.push({
          id,
          quantity: Number.parseInt(quantity),
        })
      }
      return acc
    }, [] as AddProduct[])

    if (items.length === 0)
      return

    try {
      reset()
      let cart

      for (const item of items) {
        try {
          cart = await context.$cart.addProduct(item)
        }
        catch (_) {
        }
      }

      if (!cart)
        return

      updateCart(cart)
      gtm.pushCartUpdate()
      return Promise.resolve()
    }
    catch (e) {
      return Promise.reject(e)
    }
    finally {
      loading.value = false
    }
  }

  return {
    state,
    cartToken,
    freeShipping,
    cartSummary,
    loading,
    isCartEmpty,
    removeCartToken,
    reset,
    setCartToken,
    setCartTokenFromLocalStorage,
    updateCart,
    fetchCart,
    addItem,
    addItems,
    removeItem,
    updateItemQty,
    addShippingMethodToCart,
    updateCountryCode,
    restoreCartFromQuery,
  }
})
