import type { Route } from 'types/route'

export function setWindowHeight() {
  if (typeof window === 'undefined')
    throw new TypeError('Window not initialized')

  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

export function hasHeader(route: Route) {
  if (!route.matched.length)
    return true // This is fallback for error page
  if (!route.params.storefront)
    return false

  if (!route.name)
    return true
  const blankPages = ['storefront-menu', 'storefront-cart']
  if (blankPages.includes(route.name as string))
    return false

  return true
}
