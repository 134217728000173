import type Storyblok from '~/types/vendors/storyblok'
import { normalizeRichtext } from '~/utils/storyblok/richtext'

export interface NewsletterResponse {
  _uid: string
  legal: Storyblok.Richtext
  title?: string
  button: string
  subtitle?: string
  component: 'newsletter'
  success_message: Storyblok.Richtext
  error_message: Storyblok.Richtext
  _editable: string
}

export interface Newsletter {
  title: string
  subtitle: string
  button: string
  legal: Storyblok.Richtext
  successMessage: Storyblok.Richtext
  errorMessage: Storyblok.Richtext
}

export function normalizeNewsletter(newsletter: Storyblok.Story<NewsletterResponse>): Newsletter {
  const { content } = newsletter
  const { legal, success_message, error_message } = content

  return {
    legal: normalizeRichtext(legal),
    title: content.title?.trim() || '',
    button: content.button?.trim() || '',
    subtitle: content.subtitle?.trim() || '',
    successMessage: normalizeRichtext(success_message),
    errorMessage: normalizeRichtext(error_message),
  }
}
