import { breakpoints, getImageFormat } from './index'

interface Attributes {
  quality?: string | number
  width?: number
  fallback?: boolean
}

export function processImage(url: URL, attr: Attributes): string {
  const format = getImageFormat(url)

  if (format === 'svg')
    return url.toString()

  const quality = attr.quality || (attr.width && attr.width > 720) ? 60 : 70

  return `https://a.storyblok.com${url.pathname}/m/${
    attr.width ? `${attr.width}x0/` : ''
  }filters:quality(${quality})`
}

export function srcSet(url: URL, quality: number) {
  // Remove upscaled images
  const dimensions = detectDimensions(url)
  let sizes: number[] = []

  if (dimensions) {
    const filtered = breakpoints.filter(width => width <= dimensions.width)
    sizes = filtered.length ? filtered : breakpoints
  }

  return sizes.map(width => `${processImage(url, { quality, width })} ${width}w`).join(', ')
}

export function detectDimensions(url: URL): { width: number, height: number } {
  if (url.hostname.includes('a.storyblok.com')) {
    const dimensions = /\/(\d*)x(\d*)\//.exec(url.pathname)
    if (dimensions) {
      return {
        width: Number.parseInt(dimensions[1]),
        height: Number.parseInt(dimensions[2]),
      }
    }
  }
  console.warn('[Non critical] Tried to detect dimensions but failed')
  return { width: 0, height: 0 }
}
