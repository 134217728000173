import type { MediaBlock } from '../media-block'
import type { HeroBlockV2 } from '../hero-block-v2'
import type { ProductListBlockContent } from '../product-list-block'
import type { MultiHeroBlockV2 } from '../multi-hero-block-v2'
import type { TextBlockV2 } from '../text-block-v2'
import type { TextMediaBlockV2 } from '../text-media-block-v2'
import type { NewsletterBlock } from '../newsletter-block'
import type { PromoBlock } from '../promo-block'
import type { AccordionContainerBlock } from '../accordion-container-block'
import type { AccordionMediaBlock } from '../accordion-media-block'

import type { StoryblokContent } from '~/types/storyblok'
import type Storyblok from '~/types/vendors/storyblok'

// Input type
export interface PdpEnhancedContent extends StoryblokContent {
  component: 'pdp-enhanced'
  identifier: string
  body: (
    | MediaBlock
    | HeroBlockV2
    | ProductListBlockContent
    | MultiHeroBlockV2
    | TextBlockV2
    | TextMediaBlockV2
    | NewsletterBlock
    | PromoBlock
    | AccordionContainerBlock
    | AccordionMediaBlock
  )[]
}

// Output type
export interface PdpEnhanced {
  identifier: string
  body: (
    | MediaBlock
    | HeroBlockV2
    | ProductListBlockContent
    | MultiHeroBlockV2
    | TextBlockV2
    | TextMediaBlockV2
    | NewsletterBlock
    | PromoBlock
    | AccordionContainerBlock
    | AccordionMediaBlock
  )[]
}

export function normalizePdpEnhancedBlocks(blocks: Storyblok.Story<PdpEnhancedContent>[]): PdpEnhanced[] {
  return blocks.map((block) => {
    return {
      identifier: block.content.identifier,
      body: block.content.body,
    }
  })
}
