import { normalizeProducts } from 'lib/server/attraqt'
import type { SearchRepository, Sort } from 'contracts/SearchRepositoryContract'
import { type XOSearchBody, type XOSearchItemProduct, type XOSearchItemRedirect, type XOSearchResponse, XOSearchSortBy, type XOSearchSuggestBody, type XOSearchSuggestItemProduct, type XOSearchSuggestResponse } from 'types/vendors/xo'
import defu from 'defu'
import type { Suggestion } from '../types/models/product'
import { convertFilterGroupsToXOFilters, normalizeFacets, transformCategoryToXOParameterValue, transformFacetsToXOParametersValues } from '~/types/xo/facet'

function normalizeSearchItemProducts(items: XOSearchResponse['items']) {
  return items
    .filter((item): item is XOSearchItemProduct => item.kind === 'product')
    .filter(
      ({ product }) =>
        product
        && product.recommended
        && Reflect.has(product, '_id')
        && Reflect.has(product, 'updateReason')
        && Reflect.has(product, 'children')
        && product.children.length,
    )
    .map(item => item.product)
}

function formatSortBy(sort: Sort) {
  const [attribute, order] = sort.split(':')
  return [XOSearchSortBy.parse({ attribute, order })]
}

export const XOSearchRepository: SearchRepository = ({ baseURL, tenant }) => {
  const api = $fetch.create({
    headers: {
      'Content-Type': 'application/json',
    },
  })

  const repository: ReturnType<SearchRepository> = {
    _name: 'XOSearchRepository',
    recentRequests: [],
    normalizers: {},
    async fetchMaxPriceRange(categoryFacet, searchTerm) {
      const token = tenant.id

      try {
        const response = await $fetch<number>(`/api/category-price-range/${token}`, {
          method: 'POST',
          body:
          {
            token,
            options: {
              ...(categoryFacet.category && categoryFacet.type && { facets: [transformCategoryToXOParameterValue(
                categoryFacet.category,
                categoryFacet.type,
              )] }),
              filter: 'recommended = true',
            },
            query: searchTerm || '',
          },
        })
        return response
      }
      catch (error) {
        console.error('Error fetching max price range', error)
        return null
      }
    },
    async fetchFacets(options = { limit: 0 }) {
      try {
        const { facets } = await this.searchProducts({
          filtersOnly: true,
          ...options,
        })
        return Promise.resolve({ facets })
      }
      catch (e) {
        return Promise.reject(e)
      }
    },

    async suggestProducts(options = { limit: 0 }, query = '') {
      try {
        const searchQuery = options.searchTerm || query || ''

        let filter = 'recommended = true' // item kind product

        if (options.filters) {
          const otherFilters = convertFilterGroupsToXOFilters(options.filters)

          if (otherFilters)
            filter = [filter, ...otherFilters].join(' AND ')
        }

        const suggestBody: XOSearchSuggestBody & { token: string } = {
          token: tenant.id,
          query: searchQuery,
          options: {
            customResponseMask: 'id, product(title, price, photo',
            filter,
            sortBy: [],
            groupBy: {
              attribute: 'kind',
              size: 20,
              values: ['product'],
            },
          },
        }

        const result = await api<XOSearchSuggestResponse>('/search/suggest', {
          baseURL,
          method: 'POST',
          body: suggestBody,
        })
        const { metadata, groups = [] } = result
        const items = groups[0]?.items || [] as Array<XOSearchSuggestItemProduct | XOSearchItemRedirect>

        const redirects: string[] = []
        for (const item of items) {
          if (item?.kind === 'product')
            continue
          if ('redirect' in item)
            redirects.push(item.redirect.url)
        }

        return Promise.resolve({
          requestId: metadata.id,
          id: metadata.id,
          products: items as Suggestion[],
          redirects,
        })
      }
      catch (e) {
        return Promise.reject(e)
      }
    },

    async searchProducts(options = { limit: 0 }, query = '') {
      const { facets, categoryFacet, filtersOnly } = defu(options, {
        facets: [],
        categoryFacet: undefined,
        filtersOnly: false,
      })

      try {
        const facetsSearchBody = transformFacetsToXOParametersValues(facets)
        const searchQuery = options.searchTerm || query || ''

        if (categoryFacet && categoryFacet.category && categoryFacet.type) {
          const categoryFacetSearchBody = transformCategoryToXOParameterValue(
            categoryFacet.category,
            categoryFacet.type,
          )

          facetsSearchBody.push(categoryFacetSearchBody)
        }

        let filter = 'recommended = true'

        if (options.filters) {
          const otherFilters = convertFilterGroupsToXOFilters(options.filters)

          if (otherFilters)
            filter = [filter, ...otherFilters].join(' AND ')
        }

        const body: XOSearchBody & { token: string } = {
          token: tenant.id,
          query: searchQuery,
          options: {
            ...(options.sort && { sortBy: formatSortBy(options.sort) }),
            ...(options.limit && { limit: options.limit }),
            ...(options.offset && { offset: options.offset }),
            facets: facetsSearchBody,
            filter,
          },
        }
        const performFetch = filtersOnly
          ? api<XOSearchResponse>(`/api/available-filters/${tenant.id}`, {
            method: 'POST',
            body,
          })
          : api<XOSearchResponse>('/search', {
            baseURL,
            method: 'POST',
            body,
          })
        const { metadata, items } = await performFetch

        const facetsResponse = normalizeFacets(metadata.facets)
        const finalPage = metadata.count - ((options.limit ?? 0) + (options.offset ?? 0)) <= 0
        const pages = Math.ceil(metadata.count / (options.limit ?? 0))
        const currentPage = Math.ceil((options.offset ?? 0) / (options.limit ?? 0)) + 1

        const redirects: string[] = []
        for (const item of items) {
          if (item?.kind === 'product')
            continue
          if (!Reflect.has(item, 'redirect'))
            continue

          redirects.push(item.redirect.url)
        }

        return Promise.resolve({
          id: metadata.id,
          requestId: metadata.id,
          redirects,
          facets: facetsResponse,
          products: normalizeProducts(normalizeSearchItemProducts(items), tenant),
          finalPage,
          pages,
          currentPage,
          totalProducts: metadata.count,
        })
      }
      catch (e) {
        return Promise.reject(e)
      }
    },
  }

  return repository
}
