import { isString } from 'types/generic/data-type.guards'
import { getStorefronts } from 'lib/helpers/storefronts'
import type { Environment, PossibleEnvironment } from 'types/environment'
import type { TenantKey } from 'data/tenant-ids'
import { isValidEnvironment } from './environment'
import type { Market } from './market'

// TODO-n3:  Move warehouse generation types of these to storefront-generator-contract.ts
export const warehouseRegions = ['US', 'NL'] as const
export const warehouseCodes = ['us-rsv', 'us', 'nl-rsv', 'nl'] as const

export type WarehouseCode = typeof warehouseCodes[number]
export type WarehouseRegion = typeof warehouseRegions[number]

export interface Storefront {
  storefrontCode: Environment.StorefrontCode
  contentLocale: Environment.ContentLocale
  contentKey: Environment.ContentKey
  tenantKey: TenantKey
  warehouse: {
    region: WarehouseRegion
    codes: WarehouseCode[]
  }
  markets: Market[]
  isFocusMarket: boolean
}

export function isValidStorefrontCode(code: any, environment: PossibleEnvironment | string): code is Environment.StorefrontCode | '_default' {
  if (code === '_default')
    return true
  if (!isString(code))
    return false
  if (!isValidEnvironment(environment)) {
    console.warn(`Invalid environment passed: ${environment}`)
    return false
  }

  const storefronts = getStorefronts(environment)
  return !!storefronts.find(({ storefrontCode }) => storefrontCode === code)
}
