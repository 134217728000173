import StoryblokClient from 'storyblok-js-client'

import type { StoryblokRequestParameters } from 'types/storyblok'
import consola from 'consola'
import { normalizeCategories } from '../categories/normalizeCategories'

export interface ConfigurationParams {
  version: 'draft' | 'published'
  token?: string
  client?: StoryblokClient
}

export async function fetchCategories({
  version,
  locale,
  token,
  client,
}: ConfigurationParams & { locale: string }) {
  if (!token && !client)
    throw new Error('Token or client must be provided')

  const per_page = 100
  const Storyblok = client || new StoryblokClient({
    accessToken: token,
  })

  const params: StoryblokRequestParameters = {
    token,
    version,
    per_page,
    content_type: 'category',
    excluding_fields:
      'hero,xo_tag,filter_rules,_editable,meta_title,description,meta_description',
  }

  if (locale !== 'default')
    params.language = locale

  consola.info(`Getting categories for locale: ${locale}`)
  const data = await Storyblok.getAll(
    `cdn/stories`,
    {
      ...params,
    },
  )

  return normalizeCategories(data)
}
