export const breakpoints = [
  320,
  720,
  1024,
  1920,
  3840,
]
export function getImageFormat(url: URL): string | undefined {
  return url?.pathname?.split('.').pop()?.toLowerCase()
}
