import { parseMeta } from 'lib/routing'

const defaultPageType = 'CMS PAGE'

const pageTypes: { [key: string]: string } = {
  section: 'SECTION PAGE',
  search: 'SEARCH PAGE',
  product: 'PRODUCT PAGE',
}

export default defineNuxtRouteMiddleware((to) => {
  if (process.server)
    return

  const meta = parseMeta(to)
  const gtm = useGTM()

  gtm.pushGeneralInfo((meta.context && pageTypes[meta.context]) || defaultPageType, to.fullPath)
})
