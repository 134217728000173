export const useHeaderStore = defineStore('HeaderStore', () => {
  const { message: speechBubbleMessage } = storeToRefs(useSpeechBubbleStore())

  const { currentRoute } = useRouter()

  /* Desktop sub menu */
  const desktopSubmenuOpen = ref(false)

  /* Top banner height */
  const topBannerHeight = 40

  /* Header hide on scroll */
  const { scrollDirection, scrollTop } = useScrollDirection({ topOffset: 100, minScroll: 20 })
  const hideHeaderOnScroll = ref(false)
  const headerHidden = computed(() => {
    return !speechBubbleMessage.value
      && !desktopSubmenuOpen.value
      && hideHeaderOnScroll.value
      && scrollDirection.value === 'down'
  })

  const isTopBannerExcludedRoute = computed(() => {
    const urlParts = currentRoute.value.fullPath.split('/')
    const topBannerExcludedRoutes = ['checkout']
    return !!topBannerExcludedRoutes.find(excludedRoute => urlParts.includes(excludedRoute))
  })

  const isTopBannerVisible = computed(() => scrollTop.value < topBannerHeight || scrollTop.value === 0)

  return {
    desktopSubmenuOpen,
    isTopBannerVisible,
    isTopBannerExcludedRoute,
    hideHeaderOnScroll,
    headerHidden,
  }
})
