import type { Story } from 'types/storyblok'
import type { Environment } from 'types/environment'
import type { Storefront } from 'types/models/storefront'

/**
 * This function sorts stories by content key.
 *
 * The first story in the array is the one that has the content key of the current environment.
 * The last story is the default story.
 */
export function getSortedStories(
  stories: Story[],
  contentKey: Environment.ContentKey,
  storefrontCode: Storefront['storefrontCode'],
) {
  const customOrder = [storefrontCode, contentKey, '_default']
  const storiesCopy = JSON.parse(JSON.stringify(stories)) as typeof stories
  return storiesCopy.sort((a, b) => {
    const aKey = a.slug.split('/')[0] || ''
    const bKey = b.slug.split('/')[0] || ''
    const aIndex = customOrder.indexOf(aKey)
    const bIndex = customOrder.indexOf(bKey)

    // Handle slugs not found in customOrder
    if (aIndex === -1)
      return 1
    if (bIndex === -1)
      return -1

    return aIndex - bIndex
  })
}
