import type { WidgetMap } from './widgets'
import type Storyblok from '~/types/vendors/storyblok'

// Input type
export interface SegmentedUpsellContent {
  _uid: string
  component: 'segmented-upsell'
  identifier: string
  widget: string // reference to a story
  xo_variables: string // comma separated list of variable keys
  description: Storyblok.Richtext
}

// Output type
export interface SegmentedUpsell {
  identifier: string
  widgetId: string // Actual widget id for the current tenant
  variables: string[]
  description: Storyblok.Richtext
}

export function normalizeSegmentedUpsell(
  segmentedUpsell: Storyblok.Story<SegmentedUpsellContent>[],
  widgets: WidgetMap,
): SegmentedUpsell[] {
  return segmentedUpsell.map((upsell) => {
    const { identifier, widget: widgetReference, description } = upsell.content
    const widgetId = widgets.get(widgetReference)

    if (!widgetId) {
      throw new Error(`Widget ${widgetId} not found for segmented upsell ${identifier}`)
    }

    return {
      identifier,
      widgetId,
      description,
      variables: upsell.content.xo_variables.split(','),
    }
  })
}
