import { BindOncePlugin } from 'vue-bind-once'

/**
 * TODO: Replace with useId() once upgraded to nuxt >= v3.10.0
 * - https://github.com/nuxt/nuxt/discussions/23137
 * - https://github.com/nuxt/nuxt/pull/23368
 */
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(BindOncePlugin)
})
