export function randomBetween(min: number, max: number) {
  return Math.random() * (max - min) + min
}

export function pickRandom(arr: any[]) {
  return arr[Math.floor(Math.random() * arr.length)]
}

/** Randomly select unique items from an array while ensuring no duplicates */
export function pickRandomUnique<T>(arr: T[], count: number) {
  if (arr.length === 0)
    return []

  let available: T[] = [...arr]
  const picked: T[] = []
  for (let i = 0; i < count; i++) {
    const pick = pickRandom(available)
    picked.push(pick)
    available.splice(available.indexOf(pick), 1)

    if (available.length === 0)
      available = [...arr]
  }

  return picked
}
