import { parseMeta } from 'lib/routing'
import { isCheckoutSuccessView, isCheckoutView } from 'types/checkout'

export default defineNuxtRouteMiddleware((to, _) => {
  if (process.server)
    return

  const gtm = useGTM()
  const meta = parseMeta(to)

  if (meta.isCheckoutPage && isCheckoutView(meta.view)) {
    gtm.pushCheckoutView(meta.view)

    if (isCheckoutSuccessView(meta.view)) {
      try {
        gtm.pushPurchaseEvent()
        gtm.pushProductPurchaseEvent()
      }
      catch {} // Fizzle if there was no order
    }
  }

  gtm.pushSpaPageView(to)
})
